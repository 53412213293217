import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function AdminEditPrj(props){
    const ref = useRef(null);
    const [items,setItems] = useState(props.project.categories);
    const [otherA,setOtherA] = useState('');
    const [arch,setArch] = useState(props.project.architects);
    const [otherCat,setOtherCat] = useState('');
    const [display,setDisplay] = useState('d-none');
    const [display1,setDisplay1] = useState('d-none');
    const [project,setProject] = useState(
        props.project
    );
    var f = new FormData();
    useEffect(()=>{
        setProject(props.project);
        setItems(props.project.categories);
        setArch(props.project.architects);

    },[props]);
    let navigate = useNavigate();
    function onChangeTitle(e) {
        setProject((prevState)=>({
          ...prevState,
          title: e.target.value
        }));

    }
    function onChangeDescription(e){
        setProject((prevState)=>({
          ...prevState,
          description: e.target.value
        }));


    }
    function onChangeArchitects(e){
        if(e.target.checked){
            setArch([...arch,e.target.value]);
        }else{
            var index = arch.indexOf(e.target.value);
            if (index !== -1) {
                arch.splice(index, 1);
            }
        }

    }
    function onChangeOtherA(e){
        setOtherA(e.target.value);
        if(e.target.value.length>0){
            setDisplay1('');
        }else{
            setDisplay1('d-none'); 
        }
    }
    function onOtherArchAdd(){
        setArch([...arch,otherA]);
        setOtherA('');
        setDisplay1('d-none');
    }
    function onChangeOtherCat(e){
        setOtherCat(e.target.value);
        if(e.target.value.length>0){
            setDisplay('');
        }else{
            setDisplay('d-none'); 
        }
    }
    function onOtherCatAdd(){
        setItems([...items,otherCat]);
        setOtherCat('');
        setDisplay('d-none');
    }
    function onChangeCategories(e) {
        if(e.target.checked){
            setItems([...items,e.target.value]);
        }else{
            var index = items.indexOf(e.target.value);
            if (index !== -1) {
                items.splice(index, 1);
            }
        }

    }
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
    }
    function onChangeMedia(e){
        for(let file of e.target.files){
            let postid = uuidv4();
            if(file.type==='image/jpeg' || file.type==='image/png'){
                let blob = file.slice(0,file.size,"image/jpeg");
                let newFile = new File([blob],`${postid}_project.jpeg`, { type: "image/jpeg" });
                f.append("media", newFile);
            } else if(file.type === 'video/mp4'){
                let blob = file.slice(0,file.size,"video/mp4");
                let newFile = new File([blob],`${postid}_project.mp4`, { type: "video/mp4" });
                f.append("media", newFile);
            } else{
                alert("Tipo de archivo no soportado");
                ref.current.value = null;
            }
            
        }
    }
    
    async function onSend(e){
        e.preventDefault();
        if(window.confirm("¿Estas seguro que deseas editar el projecto?")){
            const headers={
                "Content-type": "multipart/form-data",
                'x-access-token': props.statelog.jwt
        
            }
            
            f.append("title",project.title);
            f.append("description",project.description);
            for(let item of items)
                f.append("categories[]",item);
            for(let ar of arch)
                f.append("architects[]",ar);
           
            axios.patch('/projects/'+project._id,  f, {headers:headers})
                .then(respuesta => {
                    if(respuesta.data){
                   
                        
                        alert("Enhorabuena, "+respuesta.data.title+" actualizado satisfactoriamente!");
                        props.updateParent();
                        navigate('/admin/projectos',{ replace: true });
                        
                    }
                    else{
                        alert("No fue posible editar el proyecto");
                        navigate('/admin/projectos',{ replace: true });
                    }
                }).catch(error => {
                    alert("Error, no fue posible editar el proyecto err:"+error);
                    navigate('/admin/projectos',{ replace: true });
                });
        }
    }
    
    return(
        <div className='col-lg-6 mt-2'>
            <div className=" ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                Proyecto:
                            </label>
                            <input type="text" value={project.title}  onChange={onChangeTitle} className="form-control" name="title" placeholder="T&iacute;tulo del proyecto" required/>
            
                    </div>
                    <div className="row m-0">
                        
                            <label className="my-1">
                                Descripción:
                            </label>
                            <textarea value={project.description}  onChange={onChangeDescription} className="form-control" name="description" placeholder="Pequeña descripci&oacute;n" />
                        
                    </div>
                    <div className="row m-0">
                       
                            <label className="my-1">
                                Arquitectos:   
                            </label>
                            <input type="text" placeholder='Añade arquitectos' value={otherA} onChange={onChangeOtherA} className='form-control mb-3 w-75'></input><input value="+" type="button" className={display1+' p-0 mx-2 mb-3 btn btn-outline-success'} onClick={onOtherArchAdd} style={{width:"10.55%"}}/>
                            {arch.map(ar=>(
                                    <div  className="col-sm-4 d-flex form-check"  key={uuidv4()}>
                                        <input className="form-check-input"   onChange={onChangeArchitects} name="architects" type="checkbox" defaultChecked value={ar} />
                                        <label className="form-check-label" htmlFor="architects">{ar}</label>                        
                                    </div>)
                                )
                            }
                    </div>
                    <div className="row m-0">
                       
                            <label className="my-1">
                                Categor&iacute;as:   
                            </label>
                            <input type="text" placeholder='Añade nuevas categor&iacute;as' value={otherCat} onChange={onChangeOtherCat} className='form-control mb-3 w-75'></input><input value="+" type="button" className={display+' p-0 mx-2 mb-3 btn btn-outline-success'} onClick={onOtherCatAdd} style={{width:"10.55%"}}/>
                            {items.map(categoria=>(
                                    <div  className="col-sm-4 d-flex form-check"  key={categoria}>
                                        <input className="form-check-input"   onChange={onChangeCategories} name="categories" type="checkbox" defaultChecked value={categoria}/>
                                        <label className="form-check-label" htmlFor="categories">{categoria}</label>                        
                                    </div>)
                                )
                            }
                    </div>
                    <div className="row m-0 mb-3">
                       
                            <label className="my-1">
                                Media:
                            </label>
                            <input type="file" ref={ref} multiple onChange={onChangeMedia} className="form-control" name="media" />
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-primary" type="submit" value="Editar" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminEditPrj;
