import { useState, useEffect } from 'react'; 
import axios from 'axios';
import {
  useLocation,
  NavLink,
  Outlet,
  useSearchParams
} from 'react-router-dom';
import Loader from '../../../componentes/Loader';

function QueryNavLink({ to, ...props }) {
  let location = useLocation();
  return <NavLink  className="navbar-brand px-3 pb-2 border-bottom" to={'/admin'+ to + location.search} {...props} />;
}

export default function AdminBanners(props) {
    const [update,setUpdate] = useState(false);
    const [banners,setBanners] = useState(null);
    useEffect( ()=>{
         axios.get(
            '/banners'
        ).then(res => {
                if(res.data) {
                 
                    setBanners(res.data);
                   
                }
                else {
                    console.log("Error durante la peticion");
                }
            }
        ).catch(error => {
            console.log(error);
        });
        
    },[update]);
 

  let [searchParams, setSearchParams] = useSearchParams({ replace: true });

  if(!!banners){
    return(
        <div style={{ width:'100%',display: 'flex',marginBottom:"10%"}}>
            <nav className='col-lg-3' style={{ borderRight: 'solid #cfcfcf 1px', padding: '1rem' }}>
                <input
                    className='form-control'
                    placeholder='Buscar (ej. Luis)'
                    value={searchParams.get('filter') || ''}
                    onChange={(event) => {
                    let filter = event.target.value;
                    if (filter) {
                        setSearchParams({ filter }, { replace: true });
                    } else {
                        setSearchParams({}, { replace: true });
                    }
                    }}
                />
                {banners.filter((banner) => {
                    let filter = searchParams.get('filter');
                    if (!filter) return true;
                    let code = banner.code.toLowerCase();
                    return code.startsWith(filter.toLowerCase());
                }).map((banner) => (
                    <QueryNavLink
                        key={banner._id}
                        style={({ isActive }) => {
                        return {
                            display: 'block',
                            margin: '1rem 0',
                            fontWeight: isActive ? 'bold' : '',
                        };
                        }}
                        to={`/banners/${banner._id}`}
                    >
                        {banner.code}
                    </QueryNavLink>
                ))}
            </nav>
            <div className='row'>
                <Outlet context={[update,setUpdate]} />            
            </div>
        </div>
    );
  }
    return (
        <Loader/>
    );
  
  
}
