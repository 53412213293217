import { useEffect, useState } from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import axios from 'axios';
import Loader from './Loader';
import Card from './Card';
function Slider() {
  const [fbData,setFbdata] = useState(null);
  useEffect(()=>{
    axios.get('/pubsf').then(res=>{
      if(res.data){
        setFbdata(res.data.data);
      }
    }).catch(error => {
      console.log(error);
    })
  },[]);

  if (fbData) {
    return (
      <div className="row m-0 py-4 text-center" style={{ width: "100%", minHeight: "53vh" }}>
        <Carousel controls={false} interval={6600} pause={false} indicators={false} >
          {fbData.map((fbitem) => {
            if (fbitem.attachments.data[0].media && fbitem.message)
              return (
                <CarouselItem key={fbitem.message}>
                  <Card
                    img={fbitem.attachments.data[0].media.image.src}
                    content={fbitem.message}
                    link={fbitem.attachments.data[0].url}
                  />
                </CarouselItem>)
            return null;
          })}
        </Carousel>
      </div>
    );
  }
  return <Loader />; // Render loader if no data

  
}

export default Slider;
