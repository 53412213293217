import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Loader from './Loader';


const containerStyle = {
  width: '100%',
  height: '487px',
  margin:'auto'
};

const center = {

  lat:  22.7580525,
  lng: -102.5011389
};

function HomeMap() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
  })


  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

  }, [])

  

  return isLoaded ? (
      <div className='col-lg-4 p-5'>
       
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={19}
          onLoad={onLoad}
          
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>

      </div>
  ) : <Loader/>
}

export default React.memo(HomeMap)