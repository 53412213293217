import {useEffect, useState} from 'react';
import axios from 'axios';
import Loader from '../../../componentes/Loader';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';
import AdminEditBan from './AdminEditBan';

import '../../../estilos/product.css'


export default function AdminBanner(props) {
  let navigate = useNavigate();  
  let params = useParams();
  const [none,setNone] = useState('none')
  const [fs,setFs] = useState({});
  const [update,setUpdate]= useOutletContext();
  function onImgClick(){
    setFs('fs');
    setNone('');
  }
  function onOutClick(){
    setFs('');
    setNone('none');
  }
  const [banner,setBanner]=useState({
    code:'',
    link:'',
    media:'',
  });
  const storageURL = "https://storage.googleapis.com/banners-cp/";
  function updateParent(){
    setUpdate(!update);
  }
  useEffect(()=>{
      
      axios.get('/banners/'+params.bannerId)
  .then(res => {
      if(res.data){
        setBanner(res.data);
        
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });

  },[params]);
 
    if(banner)
    return (
        <div className='row'><div className='col-lg-6'><main style={{ padding: '1rem' }}>
        <h3 className='text-center'>{banner.code}</h3>
        <div onClick={onOutClick} style={{position:"fixed", overflow:"scroll",minWidth:"100vw",minHeight:"100vh", background:"#000", top:0, left:0,zIndex:1000,opacity:0.7, display:none }}></div>
        <div className={'m-auto '+fs}>
        <Carousel  variant="dark" pause={true} indicators={false} controls={false} interval={null} className="color-black">
                <Carousel.Item  key={banner.code}>
                        <img alt={banner.media} onClick={onImgClick} className='m-auto my-3 p-4 d-block' src={storageURL+banner.media}  width={"90%"}></img>
                </Carousel.Item>
            </Carousel>
        </div>
        
        
        <a href={banner.link}>{"Link: "+banner.link}</a>
    
        
   
            <button 
            className = 'm-auto mt-3 d-block btn btn-outline-danger'
            onClick = { 
                () => {
                    if(window.confirm('Se eliminará el banner, ¿desea continuar?')) {
                        axios.delete(
                            '/banners/'+params.bannerId,
                            {
                                headers: {
                                    'x-access-token': props.statelog.jwt
                                }
                            }
                        ).then(res => {
                            if(res.data) { 
                                updateParent();
                                navigate('/admin/banners');
                            }
                            else {
                                console.log("Error durante la peticion");
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                }
            }
            >
            Eliminar 
            </button> 
       
        
        </main>
        </div><AdminEditBan updateParent={updateParent} statelog={props.statelog} banner={banner}/></div>
    );
    return (<Loader/>);
}