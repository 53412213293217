import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <footer className="text-center text-lg-start text-muted mt-5">
      <div className="container text-center  mt-2">
        <div className="row mt-3">
          <div className="col-md-1 col-lg-1 col-xl-1"></div>

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">
            <p className="text-uppercase text-center mb-4">WhatsApp</p>
            <div className='m-auto d-flex'>
              <img src={require("../imagenes/WACrehas.png")} alt="" style={{ width: "130px", filter: "invert(50%)", margin: "auto", zIndex: "5" }} />
            </div>
            
          </div>

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">
            <p className="text-uppercase text-center mb-4">CREHAS PROYECTOS S.A.S. DE C.V.</p>
            <div className='m-auto d-flex'>
              <img src={require("../imagenes/logo2.png")} alt="" style={{ width: "150px", filter: "invert(50%)", margin: "auto", zIndex: "5" }} />
            </div>
          </div>
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">
            <p className="text-uppercase text-center mb-4">Un desarrollo de</p>
            <div className='m-auto d-flex'>
              <img src={require("../imagenes/adatia.png")} alt="" style={{ width: "130px", filter: "invert(50%)", margin: "auto", zIndex: "5" }} />
            </div>
          </div>
          <div className="col-md-1 col-lg-1 col-xl-1"></div>
        </div>
        <div className='row m-5'>
          <div className="col-md-5 col-lg-5 col-xl-5 m-auto">
            <div className='text-align-center'>
              <div >
                <p className='sma -0 m-0'>
                  De Ciencias Qu&iacute;micas 79 colonia SPAUAZ, Guadalupe, Zacatecas, M&eacute;xico.
                </p>
              </div>
              <div >
                <p className='sma p-0 m-0'>
                  +52 492 175 6741
                </p>
              </div>
              <div >
                <p className='sma p-0 m-0'>
                  contacto@crehasproyectos.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row p-0 m-0 border-top'>
        <div className="col p-0 m-0">
          <div className="navbar justify-content-center">
            <div className="nav-item px-2">
              <NavLink to="/privacypolicy" className="nav-link"><p className='sma m-0'>Pol&iacute;tica de privacidad</p></NavLink>
            </div>
            <div className="nav-item px-2">
              <NavLink to="/help" className="nav-link"><p className='sma m-0'>Ayuda</p></NavLink>
            </div>
            <div className="nav-item px-2">
              <NavLink to="/useterms" className="nav-link"><p className=' sma m-0'>T&eacute;rminos y condiciones de uso</p></NavLink>
            </div>
            
          </div>
        </div>
      </div>
      <div className="text-center sma p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
        <p className="sma p-0 m-0">CrehasPoyectos.com</p>
      </div>
    </footer>
  );
}

export default Footer;
