import { useEffect, useState } from 'react';
import Loader from './Loader';
import axios from 'axios';

function Instagram() {

  const [videos, setVideos] = useState([]);
  const [carousels, setCarousels] = useState([]);
  useEffect( ()=>{
    let i=0,j=0;
    axios.get('/pubsi').then( res => {
      if(res.data){
        
        const videoArray = [];
        const carouselArray = [];

        res.data.data.forEach((item) => {
          if (item.media_type === 'VIDEO' && i<2) {
            i++;
            videoArray.push(item);
          } else if (item.media_type === 'CAROUSEL_ALBUM' && j<9) {
            j++;
            carouselArray.push(item);
          }
        });
        setVideos(videoArray);
        setCarousels(carouselArray);

      }
    }).catch( error => {
      console.log(error);
    })
  },[]);

  if (videos && carousels) {
    return (
    <div className="row my-3 mx-auto instagram" >

        {carousels.map((ig,index) => {
          if(index===4)
            return(
              <div className='col-lg-4  p-1' key={ig.permalink}>
                <a href={ig.permalink}><img src={ig.media_url} className='w-100' alt={ig.permalink} /></a>
              </div>
            )
          else{
            return(<div className='col-lg-4 col-6 p-1' key={ig.permalink}>
                    <a href={ig.permalink}><img src={ig.media_url} className='w-100' alt={ig.permalink} /></a>
                    </div>);
          }
          
        })}
        {videos.map((ig) => (
          <div className='col-lg-6 p-4' key={ig.permalink}>
            <video src={ig.media_url} controls={true} className='w-100' alt={ig.permalink}>
            </video>
          </div>
        ))}
      </div>
    );
  }
  return <Loader />; // Render loader if no data

}

export default Instagram;
  