import {useEffect, useState} from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import Loader from '../../../componentes/Loader';
import UserFavProducts from './UserFavProducts';


export default function UserProfile(props) {

    let navigate = useNavigate();
    const [incorrecto,setIncorrecto] = useState(<div></div>);

    const [passwrd,setPasswrd] = useState(false);
    const [pass,setPass] = useState('');
    const [newPass,setNewPass] = useState('');
    const [edit,setEdit] = useState(false)
    const [user,setUser]=useState();
    function onChangeName(e) {
        setUser((prevState)=>({
        ...prevState,
        firstName: e.target.value
        }));

    }
    function onChangeNewPass(e){
        setNewPass( e.target.value
        );
        

    }
    function onChangePass(e){
        setPass( e.target.value
        );
        

    }
    function onChangeTel(e){
        setUser((prevState)=>({
        ...prevState,
        telefone: e.target.value
        }));


    }
   
    async function  onEditSubmit(e){
        e.preventDefault();
        if(window.confirm('Se editará el usuario, ¿desea continuar?')) {
        const headers={
            "Content-type": "application/x-www-form-urlencoded",
            'x-access-token': props.statelog.jwt
    
        }
    
        var searchParams = new URLSearchParams();
        
        searchParams.set("firstName",user.firstName);
        searchParams.set("telefone",user.telefone);
        
        
    
        axios.patch('/users/'+user._id,  searchParams.toString(), {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
                 
                    
                    alert("Enhorabuena, "+respuesta.data.firstName+" actualizado satisfactoriamente!");
                    
                    setEdit(!edit);
                }
                else{
                    alert("No fue posible editar el usuario");
                }
            }).catch(error => {
                alert("Error, no fue posible editar el usuario err:"+error);
            });
            }
    }
    async function  onPassSubmit(e){
        e.preventDefault();
        if(window.confirm('Se cambiará la contraseña, ¿desea continuar?')) {
        const headers={
            "Content-type": "application/x-www-form-urlencoded",
    
        }
    
        var searchParams = new URLSearchParams();
        
        
        searchParams.set("email",user.email);
        searchParams.set("password",pass);
        
        axios.post('/auth/login',  searchParams.toString(), {headers:headers})
        .then(respuesta => {
            if(respuesta.data){
                const headers={
                    "Content-type": "application/x-www-form-urlencoded",
                    'x-access-token': respuesta.data.jwt
            
                }
                var searchParams = new URLSearchParams();
                
                searchParams.set("password",newPass);
                axios.patch('/users/'+user._id,  searchParams.toString(), {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
      
                    alert("Enhorabuena, "+respuesta.data.firstName+" actualizado satisfactoriamente!");
                   setPasswrd(!passwrd);
                   setPass('');
                    setNewPass('');
          
                    
                }
                else{
                    alert("No fue posible editar el usuario");
                }
            }).catch(error => {
                alert("Error, no fue posible editar el usuario err:"+error+". Intentalo más tarde...");
            });
                
            }
            else{
                setPass('');
                setNewPass('');
                setIncorrecto(<div className="mb-2 alert alert-danger" role="alert" align="center">Password incorrecta</div>);
            }
        }).catch(error => {
            setPass('');
                setNewPass('');
            setIncorrecto(<div className="mb-2 alert alert-danger"  role="alert" align="center">Password incorrecta</div>);
        });
        }
    }
  useEffect(()=>{
      
      axios.get('/users/'+props.statelog.userId,{
          headers: {
            'x-access-token': props.statelog.jwt
          }
      })
  .then(res => {
      if(res.data){
        setUser(res.data);

      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });
  // eslint-disable-next-line
  },[]);
    if(user)
        return (
            <div  className='row p-0 m-0'><div className='col-lg-3 text-center'><main style={{ padding: '1rem' }}>
            <h3  className = {edit?' d-none':' pb-2'}>{user.firstName}</h3>
            
            <p  className ={edit?' d-none':' pb-0 mb-0'}>Tel&eacute;fono: {user.telefone} </p>
            <div style={
                {cursor:"pointer",float:'right'}
            }
                className = {edit?' ':' d-none'}
                onClick = { 
                    () => {
                        setEdit(!edit);
                        setIncorrecto(<div></div>);
                    }
                }
                >
                x
                
                </div> 
                <br className ={edit?' ':' d-none'}/>
            <form onSubmit={onEditSubmit
                        } className={edit?'':'d-none'}>
                   
                   
                   <div className="row m-0">
                              
                              
                              <input type="text" value={user.firstName} onChange={onChangeName} className="form-control" name="name" placeholder="Nombre" required/>
                   
                      </div>
                           
                        
                           <div className="row mt-2 m-0">
                              
                                  
                                   <input type="tel" value={user.telefone} onChange={onChangeTel} className="form-control" name="telefone" placeholder="Tel&eacute;fono" required/>
                        
                           </div>
                           
                           <div className="d-flex justify-content-center">
                                   <input className="btn btn-outline-secondary" type="submit" value="Editar datos" style={{margin : "10px"}}></input>
                           </div>
                       </form>
            <p  className='pb-2 mt-3'>e-mail: {user.email} </p>
            <p className='pb-2'>Creado: {user.created}</p>
            <div style={
                {cursor:"pointer",float:'right'}
            }
                className = {passwrd?' ':' d-none'}
                onClick = { 
                    () => {
                        setPasswrd(!passwrd);
                        setIncorrecto(<div></div>);

                    }
                }
                >
                x
                </div> 
            <br className = {passwrd?' ':' d-none'}/>
            <form onSubmit={onPassSubmit}  className={passwrd?' ':'d-none'}>
                   
                   
            <div className="row m-0">
                       
                       <label className="my-1">
                           Contraseña actual:
                       </label>
                       <input type="password" value={pass} onChange={onChangePass} className="form-control" name="pass" placeholder="password" required/>
            
               </div>
                    
                    <div className="row m-0">
                       
                            <label className="my-1">
                               Nueva contraseña:
                            </label>
                            <input type="password" value={newPass} onChange={onChangeNewPass} className="form-control" name="newPass" placeholder="password" required/>
                 
                    </div>
                    
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-secondary" type="submit" value="Cambiar contraseña" style={{margin : "10px"}}></input>
                    </div>
                </form>
       
            {incorrecto}
                
            <button 
                className = {passwrd?' d-none':' m-auto mb-2 d-block btn btn-outline-secondary'}
                onClick = { 
                    () => {
                        setPasswrd(!passwrd);
                    }
                }
                >
                Cambiar contraseña
                </button> 
                <button 
                className = {edit?' d-none':' m-auto mb-2 d-block btn btn-outline-secondary'}
                onClick = { 
                    () => {
                        setEdit(!edit);
                    }
                }
                >
                Editar Datos
                </button> 
                <button 
                className = 'm-auto d-block btn btn-outline-danger'
                onClick = { 
                    () => {
                        if(window.confirm('Se eliminará el usuario, ¿desea continuar?')) {
                            axios.delete(
                                '/users/'+props.statelog.userId,
                                {
                                    headers: {
                                        'x-access-token': props.statelog.jwt
                                    }
                                }
                            ).then(res => {
                                if(res.data) { 
                                    navigate('/home');
                                }
                                else {
                                    console.log("Error durante la peticion");
                                }
                            }).catch(error => {
                                
                                alert("No puedes borrar tu usuario");

                            });
                        }
                    }
                }
                >
                Eliminar mi cuenta
                </button> 
           
            
            </main>
            </div><UserFavProducts userId={props.statelog.userId} jwt={props.statelog.jwt}/></div>
        );

        return (<Loader/>);
}
