import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../componentes/Loader';


function AdminEditCat(props){
    const ref = useRef(null);
    const [load,setLoad] = useState(
        false
    );
    const [catalog,setCatalog] = useState(
        props.catalog
    );
    var f = new FormData();
    useEffect(()=>{
        setCatalog(props.catalog);

    },[props]);
    let navigate = useNavigate();
    function onChangeTitle(e) {
        setCatalog((prevState)=>({
          ...prevState,
          title: e.target.value
        }));

    }
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
    }
    function onChangeMedia(e){
        for(let file of e.target.files){
            let postid = uuidv4();
            if(file.type==='application/pdf'){
                let blob = file.slice(0,file.size,"application/pdf");
                let newFile = new File([blob],`${postid}_catalog.pdf`, { type: "application/pdf" });
                f.append("media", newFile);
            } 
             else{
                alert("Tipo de archivo no soportado");
                ref.current.value = null;
            }
            
        }
    }
    
    async function onSend(e){
        e.preventDefault();
        if(window.confirm("¿Estas seguro que deseas editar el catálogo?")){
            setLoad(!load);
            const headers={
                "Content-type": "multipart/form-data",
                'x-access-token': props.statelog.jwt
        
            }
            
            f.append("title",catalog.title);
            
           
            axios.patch('/catalogs/'+catalog._id,  f, {headers:headers})
                .then(respuesta => {
                    setLoad(false);
                    if(respuesta.data){
                      
                        
                        alert("Enhorabuena, "+respuesta.data.title+" actualizado satisfactoriamente!");
                        props.updateParent();
                        navigate('/admin/archivo',{ replace: true });
                        
                    }
                    else{
                        alert("No fue posible editar el catálogo");
                        navigate('/admin/archivo',{ replace: true });
                    }
                }).catch(error => {
                    alert("Error, no fue posible editar el catálogo err:"+error);
                    navigate('/admin/archivo',{ replace: true });
                });
        }
    }
    if(load)
        return <Loader/>
    return(
        <div className='col-lg-5 mt-2'>
            <div className=" ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                Cat&aacute;logo:
                            </label>
                            <input type="text" value={catalog.title}  onChange={onChangeTitle} className="form-control" name="title" placeholder="T&iacute;tulo del cat&aacute;logo" required/>
            
                    </div>
                  
                    <div className="row m-0 mb-3">
                       
                            <label className="my-1">
                                PDF:
                            </label>
                            <input type="file" ref={ref}  onChange={onChangeMedia} className="form-control" name="media" />
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-primary" type="submit" value="Editar" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminEditCat;
