import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from '../../componentes/Loader';
import Carousel from 'react-bootstrap/Carousel';
import '../../estilos/product.css'

export default function Product(props) {


  let params = useParams();
  const [none,setNone] = useState('none')
  const [fs,setFs] = useState({});
  const [product,setProduct]=useState({
    title:'',
    description:'',
    shoppingLink:'',
    images:[],
    categories:[]
  });
  function onImgClick(){
    setFs('fs');
    setNone('');
  }
  function onOutClick(){
    setFs('');
    setNone('none');
  }
  
  const storageURL = "https://storage.googleapis.com/productos-cp/";
 
  useEffect(()=>{
    
      axios.get('/products/'+params.productId)
  .then(res => {
      if(res.data){
        setProduct(res.data);
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });
  },[params]);
    if(product)
        return (
            <>
            <div className='col-1'></div>
            <div className='col-lg-3 pt-5 text-muted'><main style={{ padding: '1rem' }}>
            <h3 className='text-center'>{product.title}</h3>
            <div onClick={onOutClick} style={{position:"fixed", overflow:"scroll",minWidth:"100vw",minHeight:"100vh", background:"#000", top:0, left:0,zIndex:1000,opacity:0.7, display:none }}></div>
            
            
            <p>{product.description}</p>
            <p className='text-center'><a style={{color:"black"}} rel="noreferrer" target="_blank" href={product.shoppingLink} className='m-auto text-center'>Comprar aqu&iacute;</a>
            </p>

            <p className='text-center'>Vistas: {product.viewsNumber + ' | Favoritos:' + product.favsNumber}</p>
        
            <p className="text-center">Categor&iacute;as</p>
            <ol>
                {product.categories.map((categorie)=>(
                    <li key={categorie}>{categorie}</li>
                ))}
            </ol>
            
            </main>
            </div><div className='col-lg-4'>
            <div className={'m-auto pt-lg-5 '+fs}>
            <Carousel slide={true}  variant="dark" pause={true} indicators={true} controls={true} interval={null} className="color-black">
                {product.images.map((image)=>(
                    <Carousel.Item key={image}>
                            <img alt={image} onClick={onImgClick} className='m-auto my-3 p-4 d-block' src={storageURL+image}  width={"90%"}></img>
                    </Carousel.Item>
                ))}
                </Carousel>
            </div></div></>
        );
        return (<Loader/>);
}
