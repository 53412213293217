import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

import emailjs from '@emailjs/browser';

function Register(){
    const [view,setView] = useState(<RegisterLog/>);
    const [incorrecto,setIncorrecto] = useState(<div></div>);
    
    function RegisterLog(){

    const captcha = useRef(null);


    const [user,setUser] = useState({
        email:'',
        pass:'', 
        telefone:'',   
        name: ''
    })

    function onChange(value) {
        if(captcha.current.getValue()){
          setIncorrecto(null);
  
        }
      }
    function onChangeName(e) {
        setUser((prevState)=>({
          ...prevState,
          name: e.target.value
        }));
  
    }
    
    function onChangeTel(e){
        setUser((prevState)=>({
          ...prevState,
          telefone: e.target.value
        }));
   

    }
    function onChangeCorreo(e) {
        setUser((prevState)=>({
          ...prevState,
          email: e.target.value
        }));
     
    }
    function onChangePass(e){
        onChangeConPass(e);
        setUser((prevState)=>({
          ...prevState,
          pass: e.target.value
        }));
       
    }
    function onChangeConPass(e){
        if(e.target.value!==user.pass)
            setIncorrecto(<div  className="alert alert-danger" role="alert"  align="center">Las contraseñas no coinciden</div>);
        else
            setIncorrecto(null);

    }
    async function onSend(e){
        e.preventDefault();
        if(captcha.current.getValue() && incorrecto!=null){
            axios.get("https://api.emailable.com/v1/verify?email="+user.email+"&api_key="+process.env.REACT_APP_EMAILABLE_KEY).then(response=>{
       
            if(response.data.state === 'deliverable'){
                    var searchParams = new URLSearchParams();
        
                    searchParams.set("firstName",user.name);
                    searchParams.set("email",user.email);
                    searchParams.set("password",user.pass);
                    searchParams.set("telefone",user.telefone);
                    const headers={
                        "Content-type": "application/x-www-form-urlencoded",
                
                    }
                    
                
                    axios.post('/auth/register',  searchParams.toString(), {headers:headers})
                        .then(respuesta => {
                            if(respuesta.data){
                                setUser({
                                    email:'',
                                    pass:'',    
                                    name: '',
                                    telefone:''
                                }); 
                          
                                const templateParams = {
                                    name: user.name,
                                    email: user.email,
                                    link: "https://www.crehasproyectos.com/auth/verify/"+respuesta.data._id                                    
                                };
                                emailjs.send(process.env.REACT_APP_SERVICE_EMAILJS,process.env.REACT_APP_TEMPLATE_EMAILJS, templateParams, process.env.REACT_APP_EMAILJS_KEY)
                                .then((result) => {
                                   
                                    setIncorrecto(<div className="alert alert-success" role="alert">Por favor revisa tu correo para activar tu cuenta</div>); setIncorrecto(<div class="alert alert-success" role="alert">Por favor revisa tu correo para activar tu cuenta</div>);
            
                                    
                                }, (error) => {
                                    console.log(error.text);
                                    setIncorrecto(<div  className="alert alert-danger" role="alert"  align="center">Error al enviar correo de confirmaci&oacute;n</div>);
            
                                    
                                });
                                
                                
                            }
                            else{
                                setUser({
                                    email:'',
                                    pass:'',    
                                    name: '',
                                    telefone:''
            
                                });
                            }
                        }).catch(error => {
                            setUser({
                                email:'',
                                pass:'',    
                                name: '',
                                telefone: ''
                            });
                            
                        });
                 
                }
                else{
                setUser({
                    email:'',
                    pass:'',    
                    name: '',
                    telefone:'' 
                });
                setIncorrecto(<div  className="alert alert-danger" role="alert"  align="center">Correo Inv&aacute;lido</div>);
    
                }

            }).catch(error => {
                setUser({
                    email:'',
                    pass:'',    
                    name: '',
                    telefone: ''
                });
                
            });;
            
        }
        
        else{
            setIncorrecto(<div  className="alert alert-danger" role="alert" align="center">Porfavor valide el captcha</div>);

        }
    }
        return(<div className='m-auto pt-5'>
        <section className="d-flex  justify-content-center justify-content-center mb-0">
                <div className="tag align-items-center">
                    <h2>REGISTRAR USUARIO</h2>
                </div>
            </section>
            <p className=' col-lg-4 m-auto text-center'>Registrate para solicitar servicios, recibir ofertas especiales y muchos beneficios m&aacute;s!</p>

        <div className="m-auto  my-0 px-4">
            <form onSubmit={onSend}>
                <div className="row">
                <div className="col-lg-4 col-sm-4 m-auto ">
                        <label className="my-2">
                            Nombre:
                        </label>
                        <input type="text" value={user.name}  onChange={onChangeName} className="form-control" name="name" placeholder="Nombre" required/>
        </div>
                </div>
                <div className="row">
                <div className='col-lg-4'></div>
                <div className="col-lg-2 ">   
                        <label className="my-2">
                            Tel&eacute;fono:   
                        </label>
                        <input type="tel" value={user.telefone} onChange={onChangeTel} className="form-control" name="telefone" placeholder="Tel&eacute;fono a 10 digitos" required/>
                    </div>
                <div className="col-lg-2">
                        <label className="my-2">
                            Correo:   
                        </label>
                        <input type="email" value={user.email} onChange={onChangeCorreo} className="form-control" name="email" placeholder="tucorreo@ejemplo.com" required/>
                   </div>
                <div className='col-lg-4'></div>
                </div>
                <div className="row mb-2">
                <div className='col-lg-4'></div>
                <div className="col-lg-2 mb-2">
                        <label className="my-2">
                            Contraseña:
                        </label>
                        <input type="password" value={user.pass}  onChange={onChangePass} className="form-control" name="pass" placeholder="Password" required/>
                </div>
                <div className="col-lg-2 mb-2">
                            <label className="my-2">
                                Confirmar contraseña:
                            </label>
                            <input type="password"  onChange={onChangeConPass} className="form-control" name="pass" placeholder="Password" required/>
                </div>
                <div className='col-lg-4'></div>
                </div>
                <div className="row ">
                    <div className="col-lg-4  m-auto ">
                    
                   
                    {incorrecto /*se despliega mensaje de error*/}

                    </div>
                </div>
                

                <div className="d-flex justify-content-center mb-4">
                        <input className="btn btn-outline-success" type="submit" value="Siguiente" style={{margin : "10px"}}></input>
                </div>
                
            </form>
        </div>
    </div>
);
    }
    return(
        view
    );
}

export default Register;
