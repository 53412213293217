
import HomeMap from '../../componentes/HomeMap';
import Cartel from '../../componentes/Cartel';
import Banner from '../../componentes/Banner';

import React, { useEffect } from 'react';
import '../../estilos/home.css';

import '../../estilos/carousel.css';

import SocialMedia from '../../componentes/SocialMedia';
import { useNavigate } from 'react-router-dom';
function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/home')
        // eslint-disable-next-line
    }, [])
    return (
        <div className='overflow-hidden'>
            <Banner />
            <SocialMedia />
            <div className="row pt-2 m-0 mt-3" style={{ width: "100%" }}>
                <div className="col-2"></div>
                <HomeMap />
                <Cartel />
                <div className="col-1" ></div>
            </div>
        </div>
    );
}

export default Home;