import React, { useState } from 'react'
import '../estilos/cartel.css'
import Carousel from 'react-bootstrap/Carousel'

function Cartel(){
    const [pausa,setPausa]=useState(false);
    return (
        <div className="col-lg-4 text-muted" style={{
            display: 'block'
        }}>
            <Carousel variant='dark' controls={false} slide={false}  pause={false} interval={pausa?null:6000}  >
                <Carousel.Item onClick={()=>{setPausa(!pausa)}}>
                    
                    <div className="m-4 cnt" >
                        <div className="d-flex align-items-center justify-content-center">
                            <div className='tag'><h2>QUI&Eacute;NES SOMOS</h2>
                        </div>
                        </div>
                        <div className="px-5 pb-2 cartel fade-in">
                            <p>Somos un grupo multidisciplinario con el proposito de innovar la forma en que habitamos los espacios en nuestros tiempos, que cambian a un ritmo sorprendente. Orgullosamente mexicanos con el fin de impactar al mundo entero con el ingenio y la escencia creativa que nos caracteriza.</p>
                        </div>
                        <img src={require('../imagenes/somos.png')} alt="icono descriptivo de visión" style={{display:"block",margin:"auto", width:"120px"}}/> 
        
                    </div>
                    
                </Carousel.Item>
                <Carousel.Item onClick={()=>{setPausa(!pausa)}}>
                    <div className="m-4 cnt">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className='tag'><h2>MISI&Oacute;N</h2>
                        </div>
                        </div>
                        <div className=" px-5 pb-2 cartel fade-in">
                            <p>Porporcionar soluciones espaciales innovadoras, &uacute;nicas y con alto valor est&eacute;tico para el ser humano, sintetizando su complejidad y plasmandola en espacios con una semi&oacute;tica profunda pero elegante a la vez, dando un enfoque hol&iacute;stico a nuestros procedimietos para garantizar el desarrollo integral del usuario</p>
                        </div>
                        <img src={require('../imagenes/Mision.png')} alt="icono descriptivo de misión" style={{display:"block",margin:"auto", width:"120px"}}/> 
            
                    </div>
                </Carousel.Item>
                <Carousel.Item onClick={()=>{setPausa(!pausa)}}>
                    <div className="m-4 cnt">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className='tag'><h2>VISI&Oacute;N</h2>
                        </div>
                        </div>
                        <div className="px-5 pb-2 cartel fade-in">
                            <p>Este proyecto busca impactar en la vida de millones de personas y generar en la conciencia colectiva una identidad de marca trascendental. Buscamos generar productos y servicios de excelencia que contagien a nuestros clientes y expandir as&#237; nuestra presencia y filosof&#237;a. </p>
                        </div>
                        <img src={require('../imagenes/Vision.png')} alt="icono descriptivo de visión" style={{display:"block",margin:"auto", width:"120px"}}/> 
                    
                    </div>
                </Carousel.Item>
            </Carousel>
            
       </div >  
    );
}

export default Cartel;
     
    
    
    
    
    
    