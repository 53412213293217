import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams} from 'react-router-dom';
import Loader from '../../../componentes/Loader';

import Carousel from 'react-bootstrap/Carousel';
import '../../../estilos/product.css'

export default function UserProject(props) {

    const [project,setProject]=useState({
        title:'',
        description:'',
        architects:[],
        media:[],
        categories:[]
      });
  let params = useParams();
  const [none,setNone] = useState('none')
  const [fs,setFs] = useState({});

  function onImgClick(){
    setFs('fs');
    setNone('');
  }
  function onOutClick(){
    setFs('');
    setNone('none');
  }
  
  const storageURL = "https://storage.googleapis.com/proyectos-cp/";

  useEffect(()=>{
      
      axios.get('/projects/'+params.projectId)
  .then(res => {
      if(res.data){
        setProject(res.data);
 
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });
  },[params]);
    if(project)
        return (
            <><div className='col-lg-4'><main style={{ padding: '1rem' }}>
            <h3 className='text-center'>{project.title}</h3>
            <div onClick={onOutClick} style={{position:"fixed",minWidth:"100vw",minHeight:"100vh", background:"#000", top:0, left:0,zIndex:1000,opacity:0.7, display:none }}></div>
            
            
            
            <p className='mx-5'>{project.description}</p>
            <p className="text-center">Arquitectos:</p>
            <ol className='ms-5'>
                {project.architects.map((architect)=>(
                    <li key={architect}>{architect}</li>
                ))}
            </ol>
            <p className='text-center'>Vistas: {project.viewsNumber}</p>
        
            <p className="text-center">Categor&iacute;as</p>
            <ol className='ms-5'>
                {project.categories.map((categorie)=>(
                    <li key={categorie}>{categorie}</li>
                ))}
            </ol>           
            </main>
            </div><div className='col-4'><div className={'m-auto '+fs}>
            <Carousel slide={true} variant="dark" pause={true} indicators={true} controls={true} interval={null} className="color-black">
                {project.media.map((item)=>{
                    if(item.includes('.mp4')) 
                        return (
                        <Carousel.Item key={item}>
                            <video onClick={onImgClick} className='m-auto my-3 p-4 d-block' controls  width={"90%"}>
                                <source src={storageURL+item} type="video/mp4"/>

                            </video>
                        </Carousel.Item>);
                    else 
                    return (
                        <Carousel.Item key={item}>
                            <img alt={item} onClick={onImgClick} className='m-auto my-3 p-4 d-block' src={storageURL+item}  width={"90%"}/>
                        </Carousel.Item>);
                    
                   
                    })}
                </Carousel>
            </div></div></>
        );
        return (<Loader/>);
}
