import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import Loader from '../../../componentes/Loader';
import AdminEditPrj from './AdminEditPrj';
import Carousel from 'react-bootstrap/Carousel';
import '../../../estilos/product.css'

export default function AdminProject(props) {

  let navigate = useNavigate();
  let params = useParams();
  const [none,setNone] = useState('none')
  const [fs,setFs] = useState({});
  const [update,setUpdate]= useOutletContext();
  function onImgClick(){
    setFs('fs');
    setNone('');
  }
  function onOutClick(){
    setFs('');
    setNone('none');
  }
  const [project,setProject]=useState({
    title:'',
    description:'',
    architects:[],
    media:[],
    categories:[]
  });
  const storageURL = "https://storage.googleapis.com/proyectos-cp/";
  function updateParent(){
    setUpdate(!update);
  }
  useEffect(()=>{
      axios.get('/projects/'+params.projectId)
  .then(res => {
      if(res.data){
        setProject(res.data);
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });
  },[params]);
    if(project)
        return (
            <div className='row'><div className='col-lg-6'><main style={{ padding: '1rem' }}>
            <h3 className='text-center'>{project.title}</h3>
            <div onClick={onOutClick} style={{position:"fixed",minWidth:"100vw",minHeight:"100vh", background:"#000", top:0, left:0,zIndex:1000,opacity:0.7, display:none }}></div>
            <div className={'m-auto '+fs}>
            <Carousel slide={true} variant="dark" pause={true} indicators={true} controls={true} interval={null} className="color-black">
                {project.media.map((item)=>{
                    if(item.includes('.mp4')) 
                        return (
                        <Carousel.Item  key={item}>
                            <video onClick={onImgClick} className='m-auto my-3 p-4 d-block' controls  width={"90%"}>
                                <source src={storageURL+item} type="video/mp4"/>

                            </video>
                        </Carousel.Item>);
                    else 
                    return (
                        <Carousel.Item  key={item}>
                            <img alt={item} onClick={onImgClick} className='m-auto my-3 p-4 d-block' src={storageURL+item}  width={"90%"}/>
                        </Carousel.Item>);
                    
                   
                    })}
                </Carousel>
            </div>
            
            
            <p>{project.description}</p>
            <p className="text-center">Arquitectos:</p>
            <ol>
                {project.architects.map((architect)=>(
                    <li  key={architect}>{architect}</li>
                ))}
            </ol>
            <p className='text-center'>Creado: {project.created}</p>
            <p className='text-center'>Actualizado: {project.updated}</p>
            <p className='text-center'>Vistas: {project.viewsNumber}</p>
        
            <p className="text-center">Categor&iacute;as</p>
            <ol>
                {project.categories.map((categorie)=>(
                    <li key={categorie}>{categorie}</li>
                ))}
            </ol>
                

            
            
       
                <button 
                className = 'm-auto mt-3 d-block btn btn-outline-danger'
                onClick = { 
                    () => {
                        if(window.confirm('Se eliminará el proyecto, ¿desea continuar?')) {
                            axios.delete(
                                '/projects/'+params.projectId,
                                {
                                    headers: {
                                        'x-access-token': props.statelog.jwt
                                    }
                                }
                            ).then(res => {
                                if(res.data) { 
                                    updateParent();
                                    navigate('/admin/proyectos');
                                }
                                else {
                                    console.log("Error durante la peticion");
                                }
                            }).catch(error => {
                                console.log(error);
                            });
                        }
                    }
                }
                >
                Eliminar 
                </button> 
           
            
            </main>
            </div><AdminEditPrj updateParent={updateParent} statelog={props.statelog} project={project}/></div>
        );
        return (<Loader/>);
}
