import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import Loader from '../../../componentes/Loader';
import AdminEditPrd from './AdminEditPrd';
import Carousel from 'react-bootstrap/Carousel';
import '../../../estilos/product.css'

export default function AdminProduct(props) {

  let navigate = useNavigate();
  let params = useParams();
  const [none,setNone] = useState('none')
  const [fs,setFs] = useState({});
  const [update,setUpdate]= useOutletContext();
  function onImgClick(){
    setFs('fs');
    setNone('');
  }
  function onOutClick(){
    setFs('');
    setNone('none');
  }
  const [product,setProduct]=useState({
    title:'',
    description:'',
    shoppingLink:'',
    images:[],
    categories:[]
  });
  const storageURL = "https://storage.googleapis.com/productos-cp/";
  function updateParent(){
    setUpdate(!update);
  }
  useEffect(()=>{
      
      axios.get('/products/'+params.productId)
  .then(res => {
      if(res.data){
        setProduct(res.data);
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });
  },[params]);
    if(product)
        return (
            <div className='row'><div className='col-lg-6'><main style={{ padding: '1rem' }}>
            <h3 className='text-center'>{product.title}</h3>
            <div onClick={onOutClick} style={{position:"fixed", overflow:"scroll",minWidth:"100vw",minHeight:"100vh", background:"#000", top:0, left:0,zIndex:1000,opacity:0.7, display:none }}></div>
            <div className={'m-auto '+fs}>
            <Carousel slide={true}  variant="dark" pause={true} indicators={true} controls={true} interval={null} className="color-black">
                {product.images.map((image)=>(
                    <Carousel.Item  key={image}>
                            <img alt={image} onClick={onImgClick} className='m-auto my-3 p-4 d-block' src={storageURL+image}  width={"90%"}></img>
                    </Carousel.Item>
                ))}
                </Carousel>
            </div>
            
            
            <p>{product.description}</p>
            <p className='text-center'>Comprar en: <p>{product.shoppingLink}</p></p>
            <p className='text-center'>Creado: {product.created}</p>
            <p className='text-center'>Actualizado: {product.updated}</p>
            <p className='text-center'>Vistas: {product.viewsNumber + ' | Favoritos:' + product.favsNumber}</p>
        
            <p className="text-center">Categor&iacute;as</p>
            <ol>
                {product.categories.map((categorie)=>(
                    <li className=''>{categorie}</li>
                ))}
            </ol>
                

            
            
       
                <button 
                className = 'm-auto mt-3 d-block btn btn-outline-danger'
                onClick = { 
                    () => {
                        if(window.confirm('Se eliminará el producto, ¿desea continuar?')) {
                            axios.delete(
                                '/products/'+params.productId,
                                {
                                    headers: {
                                        'x-access-token': props.statelog.jwt
                                    }
                                }
                            ).then(res => {
                                if(res.data) { 
                                    updateParent();
                                    navigate('/admin/productos');
                                }
                                else {
                                    console.log("Error durante la peticion");
                                }
                            }).catch(error => {
                                console.log(error);
                            });
                        }
                    }
                }
                >
                Eliminar 
                </button> 
           
            
            </main>
            </div><AdminEditPrd updateParent={updateParent} statelog={props.statelog} product={product}/></div>
        );
        return (<Loader/>);
}
