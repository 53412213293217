import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams} from 'react-router-dom';
import Loader from '../../componentes/Loader';


import '../../estilos/product.css'


export default function Catalog(props) {
    
  const [catalog,setCatalog]=useState({
    title:'',
    file:''
  });
  let params = useParams();



 
  useEffect(()=>{
      

      axios.get('/catalogs/'+params.catalogId)
  .then(res => {
      if(res.data){
        setCatalog(res.data);
        
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });

  },[params]);
 
    if(catalog)
        return (
            <div className='col-lg-9'>
                <main style={{ padding: 'auto'}}>
                    <iframe src={'https://catalogos-cp.storage.googleapis.com/'+catalog.file} title={catalog.title} type="application/pdf" width="100%" height="500px">
                        <p>Unable to display PDF file. <a href={'https://catalogos-cp.storage.googleapis.com/'+catalog.file}>Download</a> instead.</p>
                    </iframe>
                </main>
            </div>
        );
        return (<Loader/>);
}
