import React from 'react';
export default function PrivacyPolicy() {

  return (
    <main  className='m-5 mt-2 p-5 pt-0 text-justify' style={{ padding: '1rem', textAlign:'justify' }}>
     <h2>Pol&#237;tica de privacidad</h2>
<p>
Esta Pol&#237;tica de privacidad describe c&#243;mo se recopila, utiliza y comparte su informaci&#243;n personal cuando visita o hace una compra en www.crehasproyectos.com (Denominado el sitio).
</p><h4>INFORMACI&#211;N PERSONAL QUE RECOPILAMOS</h4><p>
Cuando visita el Sitio, recopilamos autom&#225;ticamente cierta informaci&#243;n sobre su dispositivo, incluida informaci&#243;n sobre su navegador web, direcci&#243;n IP, zona horaria y algunas de las cookies que est&#225;n instaladas en su dispositivo. Adem&#225;s, a medida que navega por el Sitio, recopilamos informaci&#243;n sobre las p&#225;ginas web individuales o los productos que ve, las p&#225;ginas web o los t&#233;rminos de b&#250;squeda que lo remitieron al Sitio e informaci&#243;n sobre c&#243;mo interact&#250;a usted con el Sitio. Nos referimos a esta informaci&#243;n recopilada autom&#225;ticamente como “Informaci&#243;n del dispositivo”.
</p><p>Recopilamos Informaci&#243;n del dispositivo mediante el uso de las siguientes tecnolog&#237;as:
</p><p>Cookies - Aqu&#237; tienes una lista de cookies que utilizamos. Las enlistamos para que puedas elegir si quieres optar por quitarlas o no.
_session_id, unique token, sessional, Allows Shopify to store information about your session (referrer, landing page, etc).
</p><p>- Los “Archivos de registro” rastrean las acciones que ocurren en el Sitio y recopilan datos, incluyendo su direcci&#243;n IP, tipo de navegador, proveedor de servicio de Internet, p&#225;ginas de referencia/salida y marcas de fecha/horario.
</p><p>- Las “balizas web”, las “etiquetas” y los “p&#237;xeles” son archivos electr&#243;nicos utilizados para registrar informaci&#243;n sobre c&#243;mo navega usted por el Sitio.
    </p><p>Adem&#225;s, cuando hace una compra o intenta hacer una compra a trav&#233;s del Sitio, recopilamos cierta informaci&#243;n de usted, entre la que se incluye su nombre, direcci&#243;n de facturaci&#243;n, direcci&#243;n de env&#237;o, informaci&#243;n de pago (incluidos los n&#250;meros de la tarjeta de cr&#233;dito]), direcci&#243;n de correo electr&#243;nico y n&#250;mero de tel&#233;fono.  Nos referimos a esta informaci&#243;n como “Informaci&#243;n del pedido”.
</p><p>Cuando hablamos de “Informaci&#243;n personal” en la presente Pol&#237;tica de privacidad, nos referimos tanto a la Informaci&#243;n del dispositivo como a la Informaci&#243;n del pedido.
</p><h4>&#191;C&#243;mo utilizamos su informaci&#243;n personal?</h4>
<p>Usamos la Informaci&#243;n del pedido que recopilamos en general para preparar los pedidos realizados a trav&#233;s del Sitio (incluido el procesamiento de su informaci&#243;n de pago, la organizaci&#243;n de los env&#237;os y la entrega de facturas y/o confirmaciones de pedido).  Adem&#225;s, utilizamos esta Informaci&#243;n del pedido para: comunicarnos con usted; examinar nuestros pedidos en busca de fraudes o riesgos potenciales; y cuando de acuerdo con las preferencias que usted comparti&#243; con nosotros, le proporcionamos informaci&#243;n o publicidad relacionada con nuestros productos o servicios.
</p><p>Utilizamos la Informaci&#243;n del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su direcci&#243;n IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estad&#237;sticas sobre c&#243;mo nuestros clientes navegan e interact&#250;an con el Sitio y para evaluar el &#233;xito de nuestras campa&#241;as publicitarias y de marketing).
</p><h4>No rastrear</h4>
<p>Tenga en cuenta que no alteramos las pr&#225;cticas de recopilaci&#243;n y uso de datos de nuestro Sitio cuando vemos una se&#241;al de No rastrear desde su navegador.
</p><h4>Retenci&#243;n de datos</h4>
<p>Cuando realiza un pedido a trav&#233;s del Sitio, mantendremos su Informaci&#243;n del pedido para nuestros registros a menos que y hasta que nos pida que eliminemos esta informaci&#243;n.
</p><h4>Menores</h4>
<p>El sitio no est&#225; destinado a personas menores de 16 a&#241;os.
</p><h4>Cambios</h4>
<p>Podemos actualizar esta pol&#237;tica de privacidad peri&#243;dicamente para reflejar, por ejemplo, cambios en nuestras pr&#225;cticas o por otros motivos operativos, legales o reglamentarios.
</p><h4>Cont&#225;ctenos</h4>
<p>Para obtener m&#225;s informaci&#243;n sobre nuestras pr&#225;cticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, cont&#225;ctenos por correo electr&#243;nico a crehasproyectos@gmail.com o por correo mediante el uso de la informaci&#243;n que se proporciona a continuaci&#243;n:
 DE CIENCIAS QUIMICAS 79 COL SPAUAZ, GUADALUPE, ZAC, 98613, M&#233;xico.</p>
    </main>
  );
}