import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../componentes/Loader';


function AdminEditBan(props){
    const ref = useRef(null);
    const [load,setLoad] = useState(
        false
    );
    const [banner,setBanner] = useState(
        props.banner
    );
    var f = new FormData();
    useEffect(()=>{
        setBanner(props.banner);

    },[props]);
    let navigate = useNavigate();
    function onChangeCode(e) {
        setBanner((prevState)=>({
          ...prevState,
          code: e.target.value
        }));

    }
    function onChangeLink(e) {
        setBanner((prevState)=>({
          ...prevState,
          link: e.target.value
        }));

    }
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
    }
    function onChangeMedia(e){
        for(let file of e.target.files){
            let postid = uuidv4();
            if(file.type==='image/jpeg' ||  file.type==='image/png'){
                let blob = file.slice(0,file.size,"image/jpeg");
                let newFile = new File([blob],`${postid}_banner.pdf`, { type: "image/jpeg" });
                f.append("media", newFile);
            } 
             else{
                alert("Tipo de archivo no soportado");
                ref.current.value = null;
            }
            
        }
    }
    
    async function onSend(e){
        e.preventDefault();
        if(window.confirm("¿Estas seguro que deseas editar el Banner?")){
            setLoad(!load);
            const headers={
                "Content-type": "multipart/form-data",
                'x-access-token': props.statelog.jwt
        
            }
            
            f.append("code",banner.code);
            f.append("link",banner.link);
            
           
            axios.patch('/banners/'+banner._id,  f, {headers:headers})
                .then(respuesta => {
                    setLoad(false);
                    if(respuesta.data){
                      
                        
                        alert("Enhorabuena, "+respuesta.data.code+" actualizado satisfactoriamente!");
                        props.updateParent();
                        navigate('/admin/banners',{ replace: true });
                        
                    }
                    else{
                        alert("No fue posible editar el banner");
                        navigate('/admin/banners',{ replace: true });
                    }
                }).catch(error => {
                    alert("Error, no fue posible editar el banner err:"+error);
                    navigate('/admin/banners',{ replace: true });
                });
        }
    }
    if(load)
        return <Loader/>
    return(
        <div className='col-lg-5 mt-2'>
            <div className=" ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                C&oacute;digo
                            </label>
                            <input type="text" value={banner.code}  onChange={onChangeCode} className="form-control" name="code" placeholder="C&oacute;digo del banner" required/>
            
                    </div>
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                Link
                            </label>
                            <input type="text" value={banner.link}  onChange={onChangeLink} className="form-control" name="link" placeholder="Link del banner" required/>
            
                    </div>
                  
                    <div className="row m-0 mb-3">
                       
                            <label className="my-1">
                                Imagen:
                            </label>
                            <input type="file" ref={ref}  onChange={onChangeMedia} className="form-control" name="media" />
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-primary" type="submit" value="Editar" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminEditBan;
