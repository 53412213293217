import { useState, useEffect } from 'react'; 
import axios from 'axios';
import {
  useLocation,
  NavLink,
  Outlet,
  useSearchParams
} from 'react-router-dom';
import Loader from '../../componentes/Loader';

function QueryNavLink({ to, ...props }) {
  let location = useLocation();
  return <NavLink  className="navbar-brand px-3 pb-2 border-bottom" to={ to + location.search} {...props} />;
}

export default function Projects(props) {

    const [projects,setProjects] = useState(null);
    useEffect( ()=>{
        axios.get(
            '/projects'
        ).then(res => {
                if(res.data) {
              
                    setProjects(res.data);
    
                }
                else {
                    console.log("Error durante la peticion");
                }
            }
        ).catch(error => {
            console.log(error);
        });
        
    },[]);
 

  let [searchParams, setSearchParams] = useSearchParams({ replace: true });

  if(!!projects){
    return(
        <div style={{ minHeight:'81vh', width:'100%',display: 'flex' }} className='row'>
            <nav className='col-lg-3' style={{ borderRight: 'solid #cfcfcf 1px', padding: '1rem' }}>
                <input
                    className='form-control'
                    placeholder='Buscar Proyecto'
                    value={searchParams.get('filter') || ''}
                    onChange={(event) => {
                    let filter = event.target.value;
                    if (filter) {
                        setSearchParams({ filter }, { replace: true });
                    } else {
                        setSearchParams({}, { replace: true });
                    }
                    }}
                />
                {projects.filter((project) => {
                    let filter = searchParams.get('filter');
                    if (!filter) return true;
                    let title = project.title.toLowerCase();
                    return title.startsWith(filter.toLowerCase());
                }).map((project) => (
                    <QueryNavLink
                        key={project._id}
                        style={({ isActive }) => {
                        return {
                            display: 'block',
                            margin: '1rem 0',
                            fontWeight: isActive ? 'bold' : '',
                        };
                        }}
                        to={`/proyectos/${project._id}`}
                    >
                        {project.title}
                    </QueryNavLink>
                ))}
            </nav>
           
                <Outlet />            
            
        </div>
    );
  }else{
    return (
        <Loader></Loader>
    );
  }
  
}