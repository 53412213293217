import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate , useOutletContext } from 'react-router-dom';

function AdminNewUser(props){
    const [update,setUpdate] = useOutletContext();
    const [user,setUser] = useState({
        email:'',
        pass:'', 
        telefone:'',   
        name: ''
    })
    let navigate = useNavigate();
    function onChangeName(e) {
        setUser((prevState)=>({
          ...prevState,
          name: e.target.value
        }));
   
    }

    function onChangeTel(e){
        setUser((prevState)=>({
          ...prevState,
          telefone: e.target.value
        }));


    }
    function onChangeCorreo(e) {
        setUser((prevState)=>({
          ...prevState,
          email: e.target.value
        }));

    }
    function onChangePass(e){
        setUser((prevState)=>({
          ...prevState,
          pass: e.target.value
        }));
 
    }
    async function onSend(e){
        e.preventDefault();
       
        const headers={
            "Content-type": "application/x-www-form-urlencoded",
            'x-access-token': props.statelog.jwt
    
        }
    
        var searchParams = new URLSearchParams();
        
        searchParams.set("firstName",user.name);
        searchParams.set("email",user.email);
        searchParams.set("password",user.pass);
        searchParams.set("telefone",user.telefone);

        
    
        axios.post('/users',  searchParams.toString(), {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
                    
                    setUser({
                        email:'',
                        pass:'',    
                        name: '',
                        telefone:''
                    }); 
                    alert("Enhorabuena, "+respuesta.data.firstName+" registrado satisfactoriamente!")
                    setUpdate(!update);
                    navigate('/admin/users/'+respuesta.data._id)
                    
                }
                else{
                    setUser({
                        email:'',
                        pass:'',    
                        name: '',
                        telefone:''

                    });
                }
            }).catch(error => {
                setUser({
                    email:'',
                    pass:'',    
                    name: '',
                    telefone: ''
                });
                
            });
    }
    return(
        <div className='col-12'>
            <section className="d-flex  justify-content-center justify-content-center mb-0">
                    <div className="tag align-items-center">
                        <h2>AÑADIR USUARIO</h2>
                    </div>
                </section>
            <div className="m-auto row my-0 px-4">
                <form onSubmit={onSend}>
                    <div className="row">
                       
                            <label className="mb-1">
                                Nombre:
                            </label>
                            <input type="text" value={user.name}  onChange={onChangeName} className="form-control" name="name" placeholder="Nombre" required/>
            
                    </div>
                    <div className="row">
                            
                            <label className="my-1">
                                Tel&eacute;fono:   
                            </label>
                            <input type="tel" value={user.telefone} onChange={onChangeTel} className="form-control" name="telefone" placeholder="teléfono a 10 digitos" required/>
                        
                    </div>
                    <div className="row">
                       
                            <label className="my-1">
                                Correo:   
                            </label>
                            <input type="email" value={user.email} onChange={onChangeCorreo} className="form-control" name="email" placeholder="tucorreo@ejemplo.com" required/>
                       
                    </div>
                    <div className="row mb-2">
                       
                            <label className="my-1">
                                Contraseña:
                            </label>
                            <input type="password" value={user.pass}  onChange={onChangePass} className="form-control" name="pass" placeholder="password" required/>
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-success" type="submit" value="Añadir" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminNewUser;
