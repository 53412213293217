import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import Loader from '../../../componentes/Loader';
import AdminEdit from './AdminEdit';

export default function AdminUser(props) {

  let navigate = useNavigate();
  let params = useParams();
  const [update,setUpdate]= useOutletContext();
  const [user,setUser]=useState();
  function updateParent(){
    setUpdate(!update);
  }
  useEffect(()=>{
      
      axios.get('/users/'+params.userId,{
          headers: {
            'x-access-token': props.statelog.jwt
          }
      })
  .then(res => {
      if(res.data){
        setUser(res.data);

      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });
  // eslint-disable-next-line
  },[params]);
    if(user)
        return (
            <div className='row'><div className='col-lg-6'><main style={{ padding: '1rem' }}>
            <h3>{user.firstName}</h3>
            <p>Tel&eacute;fono: {user.telefone}</p>
            <p>e-mail: {user.email}</p>
            <p>Creado: {user.created}</p>
            <p>Actualizado: {user.updated}</p>
            <p>Rol: {user.role}</p>
            <p>
                Activo: {user.active?'Si':'No'}
            </p>
       
                <button 
                className = 'm-auto d-block btn btn-outline-danger'
                onClick = { 
                    () => {
                        if(window.confirm('Se eliminará el usuario, ¿desea continuar?')) {
                            axios.delete(
                                '/users/'+params.userId,
                                {
                                    headers: {
                                        'x-access-token': props.statelog.jwt
                                    }
                                }
                            ).then(res => {
                                if(res.data) { 
                                    updateParent();
                                    navigate('/admin/users');
                                }
                                else {
                                    console.log("Error durante la peticion");
                                }
                            }).catch(error => {
                                
                                alert("No puedes borrar tu usuario");

                            });
                        }
                    }
                }
                >
                Eliminar 
                </button> 
           
            
            </main>
            </div><AdminEdit updateParent={updateParent} statelog={props.statelog} user={user}/></div>
        );

        return (<Loader/>);
}
