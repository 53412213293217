
//Estilos
import 'bootstrap/dist/css/bootstrap.min.css';

//Dependencias
import { 
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Routes,
  Route,
} from 'react-router-dom';
import { useEffect, useState } from "react";

//Componentes
import Home from './layouts/public/Home';
import Register from './layouts/public/Register';
import Login from './layouts/public/Login';
import Product from './layouts/public/Product';
import Products from './layouts/public/Products';
import Project from './layouts/public/Project';
import Projects from './layouts/public/Projects';
import UseTerms from './layouts/public/UseTerms';
import PrivacyPolicy from './layouts/public/PrivacyPolicy';
import AdminBanner from './layouts/private/admin/AdminBanner';
import AdminBanners from './layouts/private/admin/AdminBanners';
import AdminProduct from './layouts/private/admin/AdminProduct';
import AdminProducts from './layouts/private/admin/AdminProducts';
import AdminProject from './layouts/private/admin/AdminProject';
import AdminProjects from './layouts/private/admin/AdminProjects';
import AdminUser from './layouts/private/admin/AdminUser';
import AdminUsers from './layouts/private/admin/AdminUsers';
import UserProduct from './layouts/private/user/UserProduct';
import UserProducts from './layouts/private/user/UserProducts';
import UserProject from './layouts/private/user/UserProject';
import UserProjects from './layouts/private/user/UserProjects';
import UserService from './layouts/private/user/UserService';
import UserServices from './layouts/private/user/UserServices';
import UserProfile from './layouts/private/user/UserProfile';
import Sale from './layouts/private/sales/Sale';
import Sales from './layouts/private/sales/Sales';
import SalesMap from './layouts/private/sales/SalesMap';
import Navbar from './componentes/Navbar';
import NavbarLog from './componentes/NavbarLog'
import Header from './componentes/Header'
import Footer from './componentes/Footer'
import AdminNewUser from './layouts/private/admin/AdminNewUser';
import AdminNewProduct from './layouts/private/admin/AdminNewProduct';
import AdminNewProject from './layouts/private/admin/AdminNewProject';
import AdminNewBanner from './layouts/private/admin/AdminNewBanner';
import AdminCatalogs from './layouts/private/admin/AdminCatalogs';
import AdminNewCatalog from './layouts/private/admin/AdminNewCatalog';
import AdminCatalog from './layouts/private/admin/AdminCatalog';
import Catalogs from './layouts/public/Catalogs';
import Catalog from './layouts/public/Catalog';
import UserCatalog from './layouts/private/user/UserCatalog';
import UserCatalogs from './layouts/private/user/UserCatalogs';
import {MdOutlineFavoriteBorder} from 'react-icons/md';
 

function getSession() {
  
  const tokenString = sessionStorage.getItem('usersession');
  const userToken = JSON.parse(tokenString);
  return userToken

}

function App() {
  const [statelog, setStatelog] = useState();
  function outSession(){
    sessionStorage.removeItem('usersession');
    setStatelog(undefined);
  }

  function setSession(userSession) {
    sessionStorage.setItem('usersession', JSON.stringify(userSession));
    setStatelog(userSession);
  }
  
  useEffect(()=>{
    const autenticar_session = getSession();
    if(autenticar_session){
      setStatelog(autenticar_session);
    }
  },[]);
  const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/home',
    children,
  }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet />;
  };
  return (
    <Router>
      {statelog ? (
        <><NavbarLog role={statelog.role} outSession={outSession}/>
        <Header /></>
      ) : (<><Navbar/>
      <Header /></>
      
      )}
      <Routes>
        <Route  index element={<Home/>} />
        <Route  path="/home" element={<Home/>} />
        <Route  path="/useterms" element={<UseTerms/>} />
        <Route  path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route element={<ProtectedRoute redirectPath = '/home' isAllowed={!statelog} />}>
          <Route  path="/login" element={<Login setSession={setSession}/>} />
        </Route>
        <Route  path="/register" element={<Register/>}/>
        <Route  path="/productos" element={<Products/>}>
        <Route 
            index 
            element={
            <main className='col-lg-3' style={{ padding: '1rem' }}>
              <p className='text-center'>Selecciona un producto para verlo</p>
            </main>
          } />
          <Route path=":productId" element={<Product/>} />
        </Route>
        <Route  path="/proyectos" element={<Projects/>}>
          <Route 
            index 
            element={
            <main className='col-lg-3' style={{ padding: '1rem' }}>
              <p className='text-center'>Selecciona un proyecto para verlo</p>
            </main>
          } />
          <Route  path=":projectId" element={<Project/>} />
        </Route>
        <Route  path="/archivo" element={<Catalogs/>}>
          <Route 
            index 
            element={
            <main className='col-lg-3' style={{ padding: '1rem' }}>
              <p className='text-center'>Selecciona un catalogo para verlo</p>
            </main>
          } />
          <Route  path=":catalogId" element={<Catalog/>} />
        </Route>
        
        <Route element={<ProtectedRoute redirectPath = '/home' isAllowed={!!statelog && statelog.role === "super"} />}>
          <Route  path="/admin/productos" element={<AdminProducts statelog={statelog}/>}>
            <Route 
              index 
              element={
              <main style={{ padding: '1rem' }}>
                <p className='text-center'>Selecciona un producto para verlo o añade uno nuevo</p>
                <AdminNewProduct statelog={statelog}/>
              </main>
            } />
            <Route  path=":productId" element={<AdminProduct statelog={statelog}/>} />
          </Route>
          <Route  path="/admin/banners" element={<AdminBanners statelog={statelog}/>}>
            <Route 
              index 
              element={
              <main style={{ padding: '1rem' }}>
                <p className='text-center'>Selecciona un banner para verlo o añade uno nuevo</p>
                <AdminNewBanner statelog={statelog}/>
              </main>
            } />
            <Route  path=":bannerId" element={<AdminBanner statelog={statelog}/>} />
          </Route>
          <Route  path="/admin/proyectos" element={<AdminProjects statelog={statelog}/>}>
            <Route 
              index 
              element={
              <main style={{ padding: '1rem' }}>
                <p className='text-center'>Selecciona un proyecto para verlo o añade uno nuevo</p>
                <AdminNewProject statelog={statelog}/>
              </main>
            } />
            <Route  path=":projectId" element={<AdminProject statelog={statelog}/>} />
          </Route>
          <Route  path="/admin/archivo" element={<AdminCatalogs statelog={statelog}/>}>
            <Route 
              index 
              element={
              <main style={{ padding: '1rem' }}>
                <p className='text-center'>Selecciona un cat&aacute;logo para verlo o añade uno nuevo</p>
                <AdminNewCatalog statelog={statelog}/>
              </main>
            } />
            <Route  path=":catalogId" element={<AdminCatalog statelog={statelog}/>} />
          </Route>
          <Route  path="/admin/users" element={<AdminUsers statelog={statelog}/>}>
            <Route 
              index 
              element={
              <main style={{ padding: '1rem' }}>
                <p className='text-center'>Selecciona un usuario para verlo o añade uno nuevo</p>
                <AdminNewUser statelog={statelog}/>
              </main>
           
            } />
            <Route  path=":userId" element={<AdminUser statelog={statelog}/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute redirectPath = '/home' isAllowed={!!statelog && statelog.role === "sales"} />}>
          <Route  path="/sales" element={<Sales/>}>
            <Route 
              index 
              element={
              <main style={{ padding: '1rem' }}>
                <p>Aqui apareceran tus ventas. Selecciona una para verla a detalle</p>
              </main>
            } />
            <Route  path=":saleId" element={<Sale/>} />
          </Route>
          <Route  path="/salesmap" element={<SalesMap/>}/>
        </Route>
        <Route element={<ProtectedRoute redirectPath = '/home' isAllowed={!!statelog && statelog.role === "user"} />}>
          <Route  path="/user/account" element={<UserProfile  statelog={statelog}/>}>
            <Route 
              index 
              element={
              <main className='col-lg-9 text-center' style={{ padding: '1rem' }}>
                <h2 style={{color:"grey"}}> Aqu&iacute; aparecer&aacute;n tus productos favoritos</h2><h1  style={{color:"grey"}}><MdOutlineFavoriteBorder/></h1>
                <p>Selecciona uno para verlo</p>
              </main>
            } />
            <Route  path="/user/account/favs/:productId" element={<UserProduct statelog={statelog}/>} />
          </Route>
          <Route  path="/user/productos" element={<UserProducts/>}>
            <Route 
              index 
              element={
              <main className='col-lg-3' style={{ padding: '1rem' }}>
                <p>Selecciona un producto para verlo</p>
              </main>
            } />
            <Route  path=":productId" element={<UserProduct statelog={statelog}/>} />
          </Route>
          <Route  path="/user/proyectos" element={<UserProjects />}>
            <Route 
              index 
              element={
              <main className='col-lg-3' style={{ padding: '1rem' }}>
                <p>Selecciona un proyecto para verlo</p>
              </main>
            } />
            <Route  path=":projectId" element={<UserProject/>} />
          </Route>
          <Route  path="/user/archivo" element={<UserCatalogs/>}>
            <Route 
              index 
              element={
              <main className='col-lg-3' style={{ padding: '1rem' }}>
                <p>Selecciona un cat&aacute;logo para verlo</p>
              </main>
            } />
            <Route  path=":catalogId" element={<UserCatalog/>} />
          </Route>
          <Route  path="/user/servicios" element={<UserServices/>}>
            <Route 
              index 
              element={
              <main className='col-lg-3' style={{ padding: '1rem' }}>
                <p>Selecciona un servicio para verlo</p>
              </main>
            } />
            <Route  path=":serviceId" element={<UserService/>} />
          </Route>
        </Route>
        
      </Routes>
      <Footer/>
    </Router>
  );

}

export default App;
