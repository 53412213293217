import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import Loader from './Loader';


function Banner() {
    const storageURL = "https://storage.googleapis.com/banners-cp/";
    const [banners, setBanners] = useState(null);
    useEffect(() => {
        axios.get(
            'https://crehasproyectos.com/lastban'
        ).then(res => {
            if (res.data) {

                setBanners(res.data);

            }
            else {
                console.log("Error durante la peticion");
            }
        }
        ).catch(error => {
            console.log(error);
        });

    }, []);
    if (banners)
        return (
            <div className="col-lg-12 imagen-con-extremos-difuminados " style={{
                display: 'block', padding: "auto", maxHeight: "46.1vh", overflow: "hidden"
            }}>
                <Carousel pause={false} style={{ maxHeight: "46.1vh" }} interval={10100} indicators={false} controls={false} >
                    {banners.map((banner) => {
                        return (
                            <Carousel.Item key={banner.code} className='carrousel'>
                                <a href={banner.link}>
                                    <img
                                        className="d-block m-auto banner"
                                        alt={banner.code}
                                        style={{ borderRadius: "2.5px", width: "104%" }}
                                        src={storageURL + banner.media}
                                    />
                                </a>
                            </Carousel.Item>);
                    })

                    }

                </Carousel>

            </div >
        );
    return (<Loader/>)
}

export default Banner;