import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, useOutletContext } from 'react-router-dom';
import Loader from '../../../componentes/Loader';
import AdminEditCat from './AdminEditCat';

import '../../../estilos/product.css'


export default function AdminCatalog(props) {
    
  let params = useParams();
  const [update,setUpdate]= useOutletContext();

  const [catalog,setCatalog]=useState({
    title:'',
    file:''
  });

  function updateParent(){
    setUpdate(!update);
  }
  useEffect(()=>{
      
      axios.get('/catalogs/'+params.catalogId)
  .then(res => {
      if(res.data){
        setCatalog(res.data);
        
      }
      else{
        console.log("Error durante la peticion");
      }
  }).catch(error => {
      console.log(error);
  });

  },[params]);
 
    if(catalog)
        return (
            <div className='row'><div className='col-lg-7'><main style={{ padding: '1rem' }}>
                    <iframe src={'https://catalogos-cp.storage.googleapis.com/'+catalog.file} title={catalog.title} type="application/pdf" width="100%" height="500px">
                        <p>Unable to display PDF file {catalog.title}. <a href={'https://catalogos-cp.storage.googleapis.com/'+catalog.file}>Download</a> instead.</p>
                    </iframe>
            </main>
            </div><AdminEditCat updateParent={updateParent} statelog={props.statelog} catalog={catalog}/></div>
        );
        return (<Loader/>);
}
