import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AdminEdit(props){
    const [user,setUser] = useState(
        props.user
    )
    
    useEffect(()=>{
        setUser(props.user);
        setUser((prevState)=>({
            ...prevState,
            pass:''
          }));
    },[props]);
    let navigate = useNavigate();
    function onChangeName(e) {
        setUser((prevState)=>({
          ...prevState,
          firstName: e.target.value
        }));
   
    }
    function onChangeTel(e){
        setUser((prevState)=>({
          ...prevState,
          telefone: e.target.value
        }));
   

    }

    function onChangeCorreo(e) {
        setUser((prevState)=>({
          ...prevState,
          email: e.target.value
        }));
     
    }
    function onChangePass(e){
        setUser((prevState)=>({
          ...prevState,
          pass: e.target.value
        }));
        
    }
    function onChangeRole(e){
        setUser((prevState)=>({
            ...prevState,
            role: e.target.value
          }));
          
    }
    function onChangeActive(e){
        setUser((prevState)=>({
            ...prevState,
            active: e.target.value
          }));
         
    }
    async function onSend(e){
        e.preventDefault();
       
        const headers={
            "Content-type": "application/x-www-form-urlencoded",
            'x-access-token': props.statelog.jwt
    
        }
    
        var searchParams = new URLSearchParams();
        
        searchParams.set("firstName",user.firstName);
        searchParams.set("telefone",user.telefone);
        searchParams.set("email",user.email);
        searchParams.set("password",user.pass);
        searchParams.set("role",user.role);
        searchParams.set("active",user.active);

        
    
        axios.patch('/users/'+user._id,  searchParams.toString(), {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
                    
                    
                    alert("Enhorabuena, "+respuesta.data.firstName+" actualizado satisfactoriamente!");
                    props.updateParent();
                    navigate('/admin/users/',{ replace: true });
                    
                }
                else{
                    alert("No fue posible editar el usuario");
                }
            }).catch(error => {
                alert("Error, no fue posible editar el usuario err:"+error);
            });
    }
    return(
        <div className='col-lg-6 mt-2'>
            <div className=" ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                Nombre:
                            </label>
                            <input type="text" value={user.firstName}  onChange={onChangeName} className="form-control" name="firstName" placeholder="Nombre" required/>
            
                    </div>
                    <div className="row m-0">
                            
                            <label className="my-1">
                                Tel&eacute;fono:   
                            </label>
                            <input type="tel" value={user.telefone} onChange={onChangeTel} className="form-control" name="telefone" placeholder="teléfono a 10 digitos" required/>
                        
                    </div>
                    <div className="row m-0">
                       
                            <label className="my-1">
                                Correo:   
                            </label>
                            <input type="email" value={user.email} onChange={onChangeCorreo} className="form-control" name="email" placeholder="tucorreo@ejemplo.com" required/>
                       
                    </div>
                    <div className="row m-0">
                       
                            <label className="my-1">
                                Contraseña:
                            </label>
                            <input type="password" value={user.pass} onChange={onChangePass} className="form-control" name="pass" placeholder="password" required/>
                 
                    </div>
                    <div className="row m-0">
                       
                            <label className="my-1">
                                Rol:
                            </label>
                            <select onChange={onChangeRole} className='form-select' name="role" required>
                                <option value="" defaultValue >Selecciona el rol</option>
                                <option value="super">Admin</option>
                                <option value="sales">Ventas</option>
                                <option value="user">User</option>
                            </select>
                      
                    </div>
                    <div className="row m-0 mb-2">
                       
                            <label className="my-1">
                                Activo:
                            </label>
                            <select onChange={onChangeActive} className='form-select' name="active" required>
                                <option value="" defaultValue >Selecciona el estado</option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                               
                            </select>
                      
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-primary" type="submit" value="Editar" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminEdit;
