import { useState, useEffect } from 'react'; 
import axios from 'axios';
import {
  useLocation,
  NavLink,
  Outlet,
  useSearchParams
} from 'react-router-dom';
import Loader from '../../../componentes/Loader';

function QueryNavLink({ to, ...props }) {
  let location = useLocation();
  return <NavLink  className="navbar-brand px-3 pb-2 border-bottom" to={to + location.search} {...props} />;
}

export default function UserCatalogs(props) {

    const [catalogs,setCatalogs] = useState(null);
    useEffect( ()=>{
         axios.get(
            '/catalogs'
        ).then(res => {
                if(res.data) {
            
                    setCatalogs(res.data);
                
                }
                else {
                    console.log("Error durante la peticion");
                }
            }
        ).catch(error => {
            console.log(error);
        });
        
    },[]);
 

  let [searchParams, setSearchParams] = useSearchParams({ replace: true });

  if(!!catalogs){
    return(
        <div style={{ minHeight:'81vh', width:'100%'}} className='row'>
            <nav className='col-lg-3' style={{ borderRight: 'solid #cfcfcf 1px', padding: '1rem' }}>
                <input
                    className='form-control'
                    placeholder='Buscar (ej. Luis)'
                    value={searchParams.get('filter') || ''}
                    onChange={(event) => {
                    let filter = event.target.value;
                    if (filter) {
                        setSearchParams({ filter }, { replace: true });
                    } else {
                        setSearchParams({}, { replace: true });
                    }
                    }}
                />
                {catalogs.filter((catalog) => {
                    let filter = searchParams.get('filter');
                    if (!filter) return true;
                    let title = catalog.title.toLowerCase();
                    return title.startsWith(filter.toLowerCase());
                }).map((catalog) => (
                    <QueryNavLink
                        key={catalog._id}
                        style={({ isActive }) => {
                        return {
                            display: 'block',
                            margin: '1rem 0',
                            fontWeight: isActive ? 'bold' : '',
                        };
                        }}
                        to={`/archivo/${catalog._id}`}
                    >
                        {catalog.title}
                    </QueryNavLink>
                ))}
            </nav>
            
                <Outlet  />            
            
        </div>
    );
  }else{
    return (
        <Loader></Loader>
    );
  }
  
}
