import {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, useOutletContext } from 'react-router-dom';
import Loader from '../../../componentes/Loader';
import Carousel from 'react-bootstrap/Carousel';
import '../../../estilos/product.css'
import {MdOutlineFavoriteBorder} from 'react-icons/md';

export default function UserProduct(props) {
  const [product,setProduct]=useState({
    title:'',
    description:'',
    shoppingLink:'',
    images:[],
    categories:[]
  });
  let params = useParams();
  const [none,setNone] = useState('none')
  const [startFlag,setStartFlag] = useState(false);
  const [fs,setFs] = useState({});
  const [btn,setBtn] = useState(false);
  const [update,setUpdate]= useOutletContext();
  function onImgClick(){
    setFs('fs');
    setNone('');
  }
  function onFavClick(){
    const headers={
      "Content-type": "application/x-www-form-urlencoded",
      'x-access-token': props.statelog.jwt

  }
 

  var searchParams = new URLSearchParams();
  
  searchParams.set("fav",btn?'no':'yes');
 
  axios.patch('/products/'+params.productId+'/fav',searchParams,{headers:headers})
  .then(res => {
      console.log(res);
      setStartFlag(!startFlag)

  }).catch(error => {
      console.log(error);
  });
  

  }
  function onOutClick(){
    setFs('');
    setNone('none');
  }
  

  const storageURL = "https://storage.googleapis.com/productos-cp/";
  
  useEffect(()=>{
      axios.get('/products/'+params.productId)
      .then(res => {
          if(res.data){
            setProduct(res.data);
    
          }
          else{
            console.log("Error durante la peticion");
          }
      }).catch(error => {
          console.log(error);
      });
      const headers={
        'x-access-token': props.statelog.jwt
  
    }
      axios.get('/users/'+props.statelog.userId+'/favsid',{headers:headers})
      .then(res => {
          if(res.data){

       
            if(res.data.favs.indexOf(params.productId)===-1){
              setBtn(false);
            }else{
              setBtn(true);

            }
          }
          else{
            console.log("Error durante la peticion");
          }
      }).catch(error => {
          console.log(error);
      });
    
      setUpdate(!update);
      // eslint-disable-next-line
  },[params,startFlag]);
    if(product)
        return (
            <><div className='col-lg-4'><main style={{ padding: '1rem' }}>
            <h3 className='text-center'>{product.title}</h3>
            <div onClick={onOutClick} style={{position:"fixed", overflow:"scroll",minWidth:"100vw",minHeight:"100vh", background:"#000", top:0, left:0,zIndex:1000,opacity:0.7, display:none }}></div>
            
            
            <p className='mx-5'>{product.description}</p>
      
            <p className='text-center'><a style={{color:"black"}} rel="noreferrer" target="_blank" href={product.shoppingLink} className='m-0'>Comprar aqu&iacute;</a>
            </p>
            <p className='text-center'>Vistas: {product.viewsNumber + ' | Favoritos: ' + product.favsNumber}</p>
        
            <p className="text-center">Categor&iacute;as</p>
            <ol className='ms-5'>
                {product.categories.map((categorie)=>(
                    <li key={categorie}>{categorie}</li>
                ))}
            </ol>
           
            <button onClick={onFavClick} className={btn ? 'btn btn-primary m-auto d-block':'btn btn-outline-primary m-auto d-block'}><MdOutlineFavoriteBorder/> </button>

            
            </main>
            
            </div><div className='col-lg-4'>
            <div className={'m-auto '+fs}>
            <Carousel slide={true}  variant="dark" pause={true} indicators={true} controls={true} interval={null} className="color-black">
                {product.images.map((image)=>(
                    <Carousel.Item key={image}>
                            <img alt={image} onClick={onImgClick} className='m-auto my-3 p-4 d-block' src={storageURL+image}  width={"90%"}></img>
                    </Carousel.Item>
                ))}
                </Carousel>
            </div></div></>
        );
        return (<Loader/>);
}
