import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

function Login(props){
    const captcha = useRef(null);

    const [email,setEmail] = useState('');
    const [pass,setPass] = useState('');
    const [incorrecto,setIncorrecto] = useState(<div></div>);
    let navigate = useNavigate();
    
    function onChange(value) {
      if(captcha.current.getValue()){
        setIncorrecto(<div></div>);

      }
    }

    function onChangeCorreo(e) {
        setEmail(e.target.value);
    }
    function onChangePass(e){
        setPass(e.target.value);
    }
    async function onLogin(e){
        e.preventDefault();
        if(captcha.current.getValue()){
            const headers={
                "Content-type": "application/x-www-form-urlencoded"
        
            }
        
            var searchParams = new URLSearchParams();
            searchParams.set("email",email);
            searchParams.set("password",pass);
        
            axios.post('/auth/login',  searchParams.toString(), {headers:headers})
                .then(respuesta => {
                    if(respuesta.data){
 
                        props.setSession(respuesta.data);  
                        navigate('/home');
                        
                    }
                    else{
                        setEmail('');
                        setPass('');
                        setIncorrecto(<div className="mb-2 alert alert-danger" role="alert" align="center">Correo o password incorrectos</div>);
                    }
                }).catch(error => {
                    setEmail('');
                    setPass('');
                    setIncorrecto(<div className="mb-2 alert alert-danger"  role="alert" align="center">Correo o password incorrectos</div>);
                });
        }
        else{

            setIncorrecto(<div className="mb-2 alert alert-danger" role="alert" align="center">Porfavor valide el captcha</div>);

        }
        
    }
    return(
        <div>

            <section className="d-flex justify-content-center justify-content-center pt-5">
            <div className="align-items-center">
                <div className='tag'><h2 className="">INICIAR SESI&Oacute;N</h2></div>
            </div>
        </section>
                
            <div className="m-auto p-4">
                <form onSubmit={onLogin}>
                    <div className="row">
                        <div className="col-lg-3 col-sm-4 m-auto mb-2">
                            <label className="mb-2">
                                Correo:   
                            </label>
                            <input type="email" value={email} onChange={onChangeCorreo} className="form-control" name="email" placeholder="tucorreo@ejemplo.com" required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-4 m-auto  mb-2">
                            <label className="mb-2">
                                Contraseña:
                            </label>
                            <input type="password" value={pass}  onChange={onChangePass} className="form-control" name="new_user_pass" placeholder="password" required/>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-4 m-auto p-3">
                        <ReCAPTCHA
                        ref={captcha}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onChange={onChange}
                    />
                        {incorrecto /*se despliega mensaje de error*/}
                        </div>

                    </div>
                    
                    
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-success" type="submit" value="Entrar" style={{margin : "10px"}}></input>
                    </div>
                   
                </form>
            </div>
        </div>
    
    );
}

export default Login;
