import React from "react";

export default function UseTerms() {

  return (
    <main className='m-5 mt-2 p-5 pt-0 text-justify' style={{ padding: '1rem', textAlign:'justify' }}>
      <h2>T&eacute;rminos y condiciones de uso</h2>
      <p>
      En el presente documento (en adelante, el “Contrato”) se establecen los t&#233;rminos y condiciones de CREHAS PROYECTOS SOCIEDAD DE ACCIONES SIMPLIFICADA, con domicilio en DE CIENCIAS QUIMICAS 79 COL SPAUAZ, GUADALUPE, ZAC, 98613, M&#201;XICO, que ser&#225;n de aplicaci&#243;n al acceso y uso por parte del Usuario de esta p&#225;gina web (el &#160;“Sitio Web”). Les rogamos lean atentamente el presente Contrato.&#160;
Al acceder, consultar o utilizar el Sitio Web, los Usuarios (“Vd.”, “usted”, “Usuario”, o “usuario”) aceptan cumplir los t&#233;rminos y condiciones establecidos en este Contrato. En caso de que usted no acepte quedar vinculado por los presentes t&#233;rminos y condiciones, no podr&#225; acceder a, ni utilizar, el Sitio Web.&#160;
CREHAS PROYECTOS SOCIEDAD DE ACCIONES SIMPLIFICADA Y CAPITAL VARIABLE y sus respectivas empresas afiliadas (en conjunto, “CREHAS PROYECTOS SAS DE CV”) se reservan el derecho de actualizar el presente Contrato siempre que lo consideren oportuno. En consecuencia, recomendamos al Usuario revisar peri&#243;dicamente las modificaciones efectuadas al Contrato.
El presente Sitio Web est&#225; dirigido exclusivamente a personas residentes en M&#233;xico. Los Usuarios residentes o domiciliados en otro pa&#237;s que deseen acceder y utilizar el Sitio Web, lo har&#225;n bajo su propio riesgo y responsabilidad, por lo que deber&#225;n asegurarse de que dichos accesos y/o usos cumplen con la legislaci&#243;n aplicable en su pa&#237;s.
</p><p>En este acto, CREHAS PROYECTOS SAS DE CV otorga al Usuario una licencia limitada, no exclusiva, intransferible, no susceptible de cesi&#243;n y revocable; para consultar y descargar, de forma temporal, una copia del contenido ofrecido en el Sitio Web, &#250;nicamente para uso personal del Usuario o dentro de su empresa, y nunca con fines comerciales.&#160;
Todo el material mostrado u ofrecido en el Sitio Web, entre otros ejemplos, el material gr&#225;fico, los documentos, textos, im&#225;genes, sonido, video, audio, las ilustraciones, el software y el c&#243;digo HTML (en conjunto, el “Contenido”), es de&#160;exclusiva propiedad de CREHAS PROYECTOS SAS DE CV o de las empresas que facilitan dicho material.&#160;
El Contenido est&#225; protegido por las leyes de copyright mexicanas, estadounidenses e internacionales, as&#237; como por las dem&#225;s leyes, reglamentos y normas aplicables a los derechos de propiedad intelectual. Salvo disposici&#243;n expresa en contrario en el presente contrato, y/o salvo que por imperativo legal ello est&#233; expresamente permitido por leyes derogatorias de las actualmente vigentes, el Usuario no podr&#225; (i) utilizar, copiar, modificar, mostrar, eliminar, distribuir, descargar, almacenar, reproducir, transmitir, publicar, vender, revender, adaptar, invertir el proceso de creaci&#243;n o crear productos derivados a partir de, el Contenido. Tampoco podr&#225; (ii) utilizar el Contenido en otras p&#225;ginas Web o en cualquier medio de comunicaci&#243;n como, por ejemplo, en un entorno de red, sin la previa autorizaci&#243;n por escrito en este sentido de CREHAS PROYECTOS SAS DE CV.
Todas las marcas comerciales, las marcas de servicio y los logos (en adelante, las “Marcas”) mostrados en el Sitio Web son propiedad exclusiva de CREHAS PROYECTOS SAS DE CV y de sus respectivos propietarios.&#160;
El Usuario no podr&#225; utilizar las Marcas en modo alguno sin la previa autorizaci&#243;n por escrito para ello de CREHAS PROYECTOS SAS DE CV y los respectivos propietarios.
</p>
<h3>Pol&#237;tica de Reembolso</h3>
<p>Nuestra pol&#237;tica dura 15 d&#237;as. Si pasaron 15 d&#237;as desde su compra, desafortunadamente no podemos ofrecerle un reembolso ni un cambio.
Para ser elegible para una devoluci&#243;n, su art&#237;culo debe estar sin usar y en la misma condici&#243;n en que lo recibi&#243;. Tambi&#233;n debe estar en el embalaje original.
Para completar su devoluci&#243;n, necesitamos un recibo o comprobante de compra.
No devuelva su compra al fabricante.
Hay ciertas situaciones en las que solo se otorgan reembolsos parciales: (si corresponde)
* Cualquier art&#237;culo que no se encuentre en su estado original, est&#233; da&#241;ado o le falten piezas por razones que no se deban a nuestro error.
* Cualquier art&#237;culo devuelto m&#225;s de 15 d&#237;as despu&#233;s de la entrega.
Reembolsos (si corresponde)
Una vez recibida e inspeccionada su devoluci&#243;n, le enviaremos un correo electr&#243;nico o por WhatsApp o el medio de comunicaci&#243;n por el cual se realiz&#243; la compra notificarle que recibimos el art&#237;culo que devolvi&#243;. Tambi&#233;n le notificaremos sobre la aprobaci&#243;n o el rechazo de su reembolso.
Si se aprueba, se procesar&#225; su reembolso y se aplicar&#225; un cr&#233;dito autom&#225;ticamente en su tarjeta de cr&#233;dito o m&#233;todo de pago original, dentro de una cierta cantidad de d&#237;as.
Reembolsos tard&#237;os o faltantes (si corresponde)
Si a&#250;n no recibi&#243; un reembolso, primero revise de nuevo su cuenta bancaria.
Luego, comun&#237;quese con la empresa de su tarjeta de cr&#233;dito. Puede demorar alg&#250;n tiempo antes de que su reembolso se vea reflejado de manera oficial.
Despu&#233;s, contacte a su banco. A menudo es necesario un tiempo de procesamiento antes de poder ver reflejado un reembolso.
Si ya hizo todo lo anterior y a&#250;n no recibi&#243; su reembolso, puede contactarnos escribiendo a crehasproyectos@gmail.com.
Art&#237;culos en oferta (si corresponde)
Solo se pueden reembolsar los art&#237;culos de precio normal. Lamentablemente, no se pueden reembolsar los art&#237;culos en oferta o descuento.
Cambios (si corresponde)
Solo reemplazamos los art&#237;culos si est&#225;n defectuosos o da&#241;ados.  Si necesita cambiarlo por el mismo art&#237;culo, env&#237;enos un correo electr&#243;nico a crehasproyectos@gmail.com y env&#237;e su art&#237;culo a: DE CIENCIAS QUIMICAS 79 COL SPAUAZ, GUADALUPE, ZAC, 98613, M&#233;xico.
Pol&#237;ticas en devoluci&#243;n con env&#237;o
Para devolver su producto, debe enviarlo por correo a: DE CIENCIAS QUIMICAS 79 COL SPAUAZ, GUADALUPE, ZAC, 98613, M&#233;xico.
Los costos de env&#237;o para devolver su art&#237;culo corren por su cuenta. Los costos de env&#237;o no son reembolsables. Si recibe un reembolso, el costo de env&#237;o de la devoluci&#243;n se deducir&#225; de su reembolso.
Seg&#250;n d&#243;nde viva, el tiempo que tome recibir el cambio de su producto puede variar.
Se recomienda usar un servicio de env&#237;o con seguimiento o comprar un seguro de env&#237;o. No garantizamos que recibiremos la devoluci&#243;n de su art&#237;culo.
</p>
<h3>Pol&#237;tica de privacidad</h3>
<p>
Esta Pol&#237;tica de privacidad describe c&#243;mo se recopila, utiliza y comparte su informaci&#243;n personal cuando visita o hace una compra en www.crehasproyectos.com (Denominado el sitio).
</p><h4>INFORMACI&#211;N PERSONAL QUE RECOPILAMOS</h4><p>
Cuando visita el Sitio, recopilamos autom&#225;ticamente cierta informaci&#243;n sobre su dispositivo, incluida informaci&#243;n sobre su navegador web, direcci&#243;n IP, zona horaria y algunas de las cookies que est&#225;n instaladas en su dispositivo. Adem&#225;s, a medida que navega por el Sitio, recopilamos informaci&#243;n sobre las p&#225;ginas web individuales o los productos que ve, las p&#225;ginas web o los t&#233;rminos de b&#250;squeda que lo remitieron al Sitio e informaci&#243;n sobre c&#243;mo interact&#250;a usted con el Sitio. Nos referimos a esta informaci&#243;n recopilada autom&#225;ticamente como “Informaci&#243;n del dispositivo”.
</p><p>Recopilamos Informaci&#243;n del dispositivo mediante el uso de las siguientes tecnolog&#237;as:
</p><p>Cookies - Aqu&#237; tienes una lista de cookies que utilizamos. Las enlistamos para que puedas elegir si quieres optar por quitarlas o no.
_session_id, unique token, sessional, Allows Shopify to store information about your session (referrer, landing page, etc).
</p><p>- Los “Archivos de registro” rastrean las acciones que ocurren en el Sitio y recopilan datos, incluyendo su direcci&#243;n IP, tipo de navegador, proveedor de servicio de Internet, p&#225;ginas de referencia/salida y marcas de fecha/horario.
</p><p>- Las “balizas web”, las “etiquetas” y los “p&#237;xeles” son archivos electr&#243;nicos utilizados para registrar informaci&#243;n sobre c&#243;mo navega usted por el Sitio.
    </p><p>Adem&#225;s, cuando hace una compra o intenta hacer una compra a trav&#233;s del Sitio, recopilamos cierta informaci&#243;n de usted, entre la que se incluye su nombre, direcci&#243;n de facturaci&#243;n, direcci&#243;n de env&#237;o, informaci&#243;n de pago (incluidos los n&#250;meros de la tarjeta de cr&#233;dito]), direcci&#243;n de correo electr&#243;nico y n&#250;mero de tel&#233;fono.  Nos referimos a esta informaci&#243;n como “Informaci&#243;n del pedido”.
</p><p>Cuando hablamos de “Informaci&#243;n personal” en la presente Pol&#237;tica de privacidad, nos referimos tanto a la Informaci&#243;n del dispositivo como a la Informaci&#243;n del pedido.
</p><h4>&#191;C&#243;mo utilizamos su informaci&#243;n personal?</h4>
<p>Usamos la Informaci&#243;n del pedido que recopilamos en general para preparar los pedidos realizados a trav&#233;s del Sitio (incluido el procesamiento de su informaci&#243;n de pago, la organizaci&#243;n de los env&#237;os y la entrega de facturas y/o confirmaciones de pedido).  Adem&#225;s, utilizamos esta Informaci&#243;n del pedido para: comunicarnos con usted; examinar nuestros pedidos en busca de fraudes o riesgos potenciales; y cuando de acuerdo con las preferencias que usted comparti&#243; con nosotros, le proporcionamos informaci&#243;n o publicidad relacionada con nuestros productos o servicios.
</p><p>Utilizamos la Informaci&#243;n del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su direcci&#243;n IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estad&#237;sticas sobre c&#243;mo nuestros clientes navegan e interact&#250;an con el Sitio y para evaluar el &#233;xito de nuestras campa&#241;as publicitarias y de marketing).
</p><h4>No rastrear</h4>
<p>Tenga en cuenta que no alteramos las pr&#225;cticas de recopilaci&#243;n y uso de datos de nuestro Sitio cuando vemos una se&#241;al de No rastrear desde su navegador.
</p><h4>Retenci&#243;n de datos</h4>
<p>Cuando realiza un pedido a trav&#233;s del Sitio, mantendremos su Informaci&#243;n del pedido para nuestros registros a menos que y hasta que nos pida que eliminemos esta informaci&#243;n.
</p><h4>Menores</h4>
<p>El sitio no est&#225; destinado a personas menores de 16 a&#241;os.
</p><h4>Cambios</h4>
<p>Podemos actualizar esta pol&#237;tica de privacidad peri&#243;dicamente para reflejar, por ejemplo, cambios en nuestras pr&#225;cticas o por otros motivos operativos, legales o reglamentarios.
</p><h4>Cont&#225;ctenos</h4>
<p>Para obtener m&#225;s informaci&#243;n sobre nuestras pr&#225;cticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, cont&#225;ctenos por correo electr&#243;nico a crehasproyectos@gmail.com o por correo mediante el uso de la informaci&#243;n que se proporciona a continuaci&#243;n:
 DE CIENCIAS QUIMICAS 79 COL SPAUAZ, GUADALUPE, ZAC, 98613, M&#233;xico.
</p><h3>Pol&#237;ticas de Garant&#237;a</h3>
<p>
Con el fin de ofrecer un servicio de calidad a nuestros clientes, a continuaci&#243;n, indicamos las pol&#237;ticas de garant&#237;a para los suministros y servicios comercializados por CREHAS PROYECTOS SAS DE CV.
</p><h4>Condiciones de Garant&#237;a</h4>
<p>CREHAS PROYECTOS SAS DE CV garantiza al cliente que los productos comercializados por la empresa est&#225;n libres de defecto de fabricaci&#243;n por el per&#237;odo indicado para cada producto, a partir de la fecha de firma de la factura y/o acta entrega recepci&#243;n, bajo las siguientes condiciones:
</p><ol><li>1. CREHAS PROYECTOS SAS DE CV sea notificado sobre el producto defectuoso.
</li><li>2. Que CREHAS PROYECTOS SAS DE CV haya confirmado la correcta aplicaci&#243;n e instalaci&#243;n de los productos y se demuestre mediante el recibo de pago del servicio de supervisi&#243;n o en su caso instalaci&#243;n expedido por parte de CREHAS PROYECTOS SAS DE CV.
</li><li>3. La garant&#237;a cubre aquellos defectos que surgiesen como resultado del uso normal del producto y no por aquellos defectos que resulten por ser utilizado en condiciones distintas de las normales.
</li><li>4. Que el equipo no haya sido da&#241;ado, mal empleado, o se haya dado mantenimiento incorrecto, y/o se haya reparado durante el periodo de garant&#237;a por parte de personas o empresas ajenas a CREHAS PROYECTOS SAS DE CV.
</li><li>5. Que la falla o el defecto no haya sido causado por desgaste ordinario o provocado por mal manejo durante su transporte.
</li><li>6. Que el defecto no sea el resultado de da&#241;o por agua o inundaci&#243;n, de fuego, de explosi&#243;n, de terremotos, de tornados, de polvo excesivo, de actos de agresi&#243;n o guerra, o de alg&#250;n fen&#243;meno similar ajeno al proceso de fabricaci&#243;n y/o a la operaci&#243;n normal del producto.
</li><li>7. Que los accesorios o consumibles empleados con el equipo sean los originales aprobados por CREHAS PROYECTOS SAS DE CV.
</li><li>8. Que el equipo no haya sido almacenado en las siguientes condiciones: exposici&#243;n a luz solar, humedad, polvo excesivo, lluvia.
</li></ol><p>CREHAS PROYECTOS SAS DE CV, tendr&#225; la opci&#243;n de realizar la reparaci&#243;n o sustituci&#243;n del producto, a partir de la inspecci&#243;n, misma que ser&#225; realizada, sin excepci&#243;n, en las instalaciones de la compa&#241;&#237;a; en los casos en que los productos hayan sido instalados el medio de verificaci&#243;n ser&#225; a trav&#233;s de la prueba correspondiente de laboratorio que demuestre el incumplimiento de las medidas de calidad del producto. Por lo que el cliente est&#225; obligado al env&#237;o o entrega del producto y/o muestra de laboratorio a nuestras oficinas en DE CIENCIAS QUIMICAS #79 COL. SPAUAZ GUADALUPE ZACATECAS. Al momento de la entrega de los equipos en nuestras oficinas se generar&#225; un FOLIO DE SERVICIO AL CLIENTE.
</p><p>CREHAS PROYECTOS SAS DE CV, se reserva el derecho de realizar cambios en la comercializaci&#243;n de cualquiera de sus productos, sin incurrir en ninguna obligaci&#243;n de realizar el mismo cambio en las unidades similares vendidas previamente.
</p><h4>Periodo de Garant&#237;a</h4>
<p>El periodo de garant&#237;a se considerar&#225;, sin excepci&#243;n alguna, a partir de la fecha en que CREHAS PROYECTOS SAS DE CV. emita la factura y/o acta entrega recepci&#243;n de los suministros, repuestos y servicios, hasta el tiempo indicado a continuaci&#243;n:
</p><p>– Eco Maderas: Solo Garant&#237;a de Fabricante. Asimismo, en los casos en que el cliente as&#237; lo decida CREHAS PROYECTOS SAS DE CV participar&#225; como mediador y gestor de la garant&#237;a del fabricante.
</p><p>– Chukum. Solo Garant&#237;a de Fabricante. Asimismo, en los casos en que el cliente as&#237; lo decida CREHAS PROYECTOS SAS DE CV participar&#225; como mediador y gestor de la garant&#237;a del fabricante.
-Microcemento. Solo Garant&#237;a de Fabricante. Asimismo, en los casos en que el cliente as&#237; lo decida CREHAS PROYECTOS SAS DE CV participar&#225; como mediador y gestor de la garant&#237;a del fabricante.
</p><p>El cliente podr&#225; reclamar la garant&#237;a dentro del per&#237;odo indicado entregando lo siguiente:
</p><p>– La factura de su compra (original o copia) donde conste la descripci&#243;n y cantidad del producto adquirido, en caso de que la factura no presente detalles anexar el acta de entrega recepci&#243;n (original o copia) con las cuestiones respectivas.
</p><p>CREHAS PROYECTOS SAS DE CV bajo ninguna circunstancia ser&#225; responsable directa o indirectamente por perdidas de informaci&#243;n del cliente, almacenada en cualquiera de los equipos comercializados.
</p><h3>Pol&#237;ticas de env&#237;o</h3>
<p>
<h4>Procesamiento</h4>
Una vez expedido el comprobante de confirmaci&#243;n de pedido, se comenzar&#225; con el procesamiento del pedido. Todos los pedidos se manejan y se env&#237;an desde los almacenes de nuestros proveedores. Por favor, espere un tiempo adicional para que su pedido sea procesado durante las vacaciones y las temporadas de rebajas. Procesamos pedidos de lunes a viernes. Los pedidos se procesar&#225;n dentro de 1-3 d&#237;as h&#225;biles a partir de la fecha del pedido y se recibir&#225;n en nuestros almacenes dentro de 3-5 d&#237;as h&#225;biles. Asimismo, los pedidos se enviar&#225;n al d&#237;a siguiente despu&#233;s del d&#237;a de ingreso a nuestros almacenes. Tenga en cuenta que no enviamos los fines de semana.
<h4>Tiempos de Entrega</h4>
Una vez que realice su pedido, espere de 1 a 3 d&#237;as h&#225;biles para procesar sus pedidos. Despu&#233;s de eso, tomar&#225; entre 5 y 7 d&#237;as h&#225;biles para la entrega en los Guadalupe y zonas conurbadas, y entre 8 y 10 d&#237;as h&#225;biles para los fuera de este municipio (seg&#250;n la ubicaci&#243;n).

      </p>
      <p>Atentamente</p>
<p>CREHAS PROYECTOS SAS DE CV</p>
    </main>
  );
}