import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';



function AdminNewCatalog(props){
    const ref = useRef(null);
    const [update,setUpdate] = useOutletContext();
    const [catalog,setCatalog] = useState({
        title:''
    });
    var f = new FormData();
    let navigate = useNavigate();
    function onChangeTitle(e) {
        setCatalog((prevState)=>({
          ...prevState,
          title: e.target.value
        }));
 
    }
   
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
    }
    function onChangeMedia(e){
        for(let file of e.target.files){
            let postid = uuidv4();
            if(file.type==='application/pdf'){
                let blob = file.slice(0,file.size,"application/pdf");
                let newFile = new File([blob],`${postid}_catalog.pdf`, { type: "application/pdf" });
                f.append("media", newFile);
            } 
             else{
                alert("Tipo de archivo no soportado");
                ref.current.value = null;
            }
            
        }
    }
    
    async function onSend(e){
        e.preventDefault();
       
        const headers={
            "Content-type": "multipart/form-data",
            'x-access-token': props.statelog.jwt
    
        }
        
        f.append("title",catalog.title);
        axios.post('/catalogs',  f, {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
                    setCatalog({
                        title:'',
                    });
                    ref.current.value=null;
                    alert("Enhorabuena, "+respuesta.data.title+" añadido satisfactoriamente!");
                    setUpdate(!update);
                    navigate('/admin/archivo',{ replace: true });
                    
                }
                else{
                    alert("No fue posible añadir el catálogo");
                    setCatalog({
                        title:''  
                    });
                    ref.current.value=null;
                    navigate('/admin/archivo',{ replace: true });
                }
            }).catch(error => {
                alert("Error, no fue posible añadir el catálogo err:"+error);
                setCatalog({
                    title:''
                }); 
                ref.current.value=null;
                navigate('/admin/archivo',{ replace: true });
            });
    }
    
    return(
        <div className='col-12'>
            <section className="d-flex  justify-content-center justify-content-center mb-0">
                    <div className="tag align-items-center">
                        <h2>AÑADIR CAT&Aacute;LOGO</h2>
                    </div>
                </section>
            <div className="m-auto row my-0 px-4 ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                Cat&aacute;logo:
                            </label>
                            <input type="text" value={catalog.title}  onChange={onChangeTitle} className="form-control" name="title" placeholder="T&iacute;tulo del cat&aacute;logo" required/>
            
                    </div>
                   
                    <div className="row m-0 mb-3">
                       
                            <label className="my-1">
                                PDF:
                            </label>
                            <input ref={ref} type="file"  onChange={onChangeMedia} className="form-control" name="media" required/>
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-success" type="submit" value="Añadir" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminNewCatalog;
