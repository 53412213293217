import React from "react";
import Instagram from "./Instagram";
import Slider from "./Slider";

function SocialMedia(){
    return(
        <div className="row  m-0 " style={{width: 100+"%"}}>
  
            <Instagram/>
            <Slider/>
            
        </div> 
    )

}
export default SocialMedia;