import React,{useState} from 'react'
import {AiOutlineLogout} from 'react-icons/ai';
import {AiOutlineUser} from 'react-icons/ai'
import { //Librerias para la navegacion entre diferentes paginas
    NavLink
} from 'react-router-dom';
function NavbarLog(props){
    const [toggle,setToggle] = useState(false);
    function onMenuClick(){
        setToggle(!toggle);
    }

    if(props.role === "super")
        return (
            <div className={toggle?"w-100 bg-white":"w-100 bg-white nave"} style={{zIndex:100,top:0, position: "sticky"}}>
                <nav className="navbar navbar-expand-lg p-3 py-1 navbar-light">
                
                <button onClick={onMenuClick} className="navbar-toggler" type="button" >
                <span className="navbar-toggler-icon"></span>
            </button>
                <div className={toggle?"navbar-collapse py-2 encabezado":"navbar-collapse collapse py-2 encabezado"}>
                <div className='m-auto w-25'>
                <NavLink onClick={props.outSession}  className="nav-link p-1 m-1 btn btn-outline-light" style={{color:"#000",marginRight:"5px"}} to="/login"><AiOutlineLogout style={{marginBottom:"3px", paddingBottom:"0.7px"}}/> Cerrar Sesi&oacute;n </NavLink> 
                </div>
                    <ul className="navbar-nav m-auto">
                    <li className="nav-item m-auto active">
                            <NavLink className="nav-link py-1 " to="/home">Inicio</NavLink>
                    </li>
                    <li className="nav-item m-auto">
                        <NavLink className="nav-link py-1" to="/admin/proyectos">Proyectos</NavLink>
                    </li>
                    <li className="nav-item m-auto">
                        <NavLink className="nav-link py-1" to="/admin/productos">Productos</NavLink>
                    </li>
                    <li className="nav-item m-auto">
                        <NavLink className="nav-link py-1" to="/admin/archivo">Archivo</NavLink>
                    </li>
                    <li className="nav-item m-auto">
                        <NavLink className="nav-link py-1" to="/admin/users">Usuarios</NavLink>
                    </li>
                    <li className="nav-item m-auto">
                        <NavLink className="nav-link py-1" to="/admin/banners">Banners</NavLink>
                    </li>
                    </ul>
                </div>
            </nav>
            </div>
        );
        else if(props.role === "sales")
            return(<div className="w-100 bg-white nave" style={{zIndex:100,top:0, position: "sticky"}}>
            <nav className="navbar navbar-expand-lg p-3 py-1 navbar-light">
            
            <button onClick={onMenuClick} className="navbar-toggler" type="button" >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={toggle?"navbar-collapse py-2 encabezado":"navbar-collapse collapse py-2 encabezado"}>
            <div className='m-auto w-25'>
                <NavLink onClick={props.outSession}  className="nav-link p-1 m-1 btn btn-outline-light" style={{color:"#000",marginRight:"5px"}} to="/login"><AiOutlineLogout style={{marginBottom:"3px", paddingBottom:"0.7px"}}/> Cerrar Sesi&oacute;n </NavLink>

            </div>
                <ul className="navbar-nav m-auto">
                <li className="nav-item m-auto active">
                    <NavLink className="nav-link py-1" to="/home">Inicio</NavLink>
                </li>
                <li className="nav-item m-auto">
                    <NavLink className="nav-link py-1" to="/proyectos">Proyectos</NavLink>
                </li>
                <li className="nav-item m-auto">
                    <NavLink className="nav-link py-1" to="/productos">Productos</NavLink>
                </li>
                <li className="nav-item m-auto">
                    <NavLink className="nav-link py-1 disabled" to="/ss">Servicios</NavLink>
                </li>
                </ul>
            </div>

        </nav>
        </div>);
        else
            return(
                <div className={toggle?"w-100 bg-white":"w-100 bg-white nave"} style={{zIndex:100,top:0, position: "sticky"}}>
                    <nav className="navbar navbar-expand-lg p-3 py-1 navbar-light">
                    
                    <button onClick={onMenuClick} className="navbar-toggler" type="button" >
                <span className="navbar-toggler-icon"></span>
            </button>
                    <div className={toggle?"navbar-collapse py-2 encabezado":"navbar-collapse collapse py-2 encabezado"}>
                        <div className='m-auto w-25'>
                            <NavLink onClick={props.outSession}  className="nav-link p-1 m-1 btn btn-outline-light" style={{color:"#000",marginRight:"5px"}} to="/login"><AiOutlineLogout style={{marginBottom:"3px", paddingBottom:"0.7px"}}/> Cerrar Sesi&oacute;n </NavLink>

                        </div>
                        <ul className="navbar-nav m-auto">
                        <li className="nav-item active">
                            <NavLink className="nav-link " to="/home">Inicio</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/proyectos">Proyectos</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/productos">Productos</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/archivo">Archivo</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link disabled" to="/user/servicios">Servicios</NavLink>
                        </li>
                        </ul>
                    </div>
                    <NavLink className="nav-item btn btn-outline-light" style={{color:"#000",marginRight:"20px"}} to="/user/account"> <AiOutlineUser style={{marginBottom:"4px",}}/> Mi Cuenta </NavLink>
                                  

                    </nav>
                </div>
            );
}

export default NavbarLog;