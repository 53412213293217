import { useState, useEffect } from 'react'; 
import axios from 'axios';
import {
  useLocation,
  NavLink,
  Outlet,
  useSearchParams
} from 'react-router-dom';
import Loader from '../../../componentes/Loader';

function QueryNavLink({ to, ...props }) {
  let location = useLocation();
  return <NavLink  className="navbar-brand px-3 pb-2 border-bottom" to={ to + location.search} {...props} />;
}


export default function UserFavProducts(props) {
    const [products,setProducts] = useState(null);
    const [update,setUpdate] = useState(false);
    const headers={
        'x-access-token': props.jwt
  
    }
    useEffect( ()=>{
        
         axios.get(
            '/users/'+props.userId+'/favs', {headers:headers}
        ).then(res => {
                if(res.data) {
                    console.log(res.data);
                    setProducts(res.data.favs);
                    console.log(products)
                }
                else {
                    console.log("Error durante la peticion");
                }
            }
        ).catch(error => {
            console.log(error);
        });
        // eslint-disable-next-line
    },[update]);
 

  let [searchParams, setSearchParams] = useSearchParams({ replace: true });

  if(!!products){
    return(
        <div style={{minHeight:'81vh',display: 'flex' }} className='col-lg-9'>
        <nav   style={{ borderRight: 'solid #cfcfcf 1px', padding: '1rem' }}>
            <input
                className='form-control'
                placeholder='Buscar en favoritos'
                value={searchParams.get('filter') || ''}
                onChange={(event) => {
                let filter = event.target.value;
                if (filter) {
                    setSearchParams({ filter }, { replace: true });
                } else {
                    setSearchParams({}, { replace: true });
                }
                }}
            />
            {products.map((product) => (
                <QueryNavLink
                    key={product._id}
                    style={({ isActive }) => {
                    return {
                        display: 'block',
                        margin: '1rem 0',
                        fontWeight: isActive ? 'bold' : '',
                    };
                    }}
                    to={`/user/account/favs/${product._id}`}
                >
                    {product.title}
                </QueryNavLink>
            ))}
        </nav>
      
               
        <Outlet context={[update,setUpdate]} />   
    </div>
         
    );
  }else{
    return (
        <Loader/>
    );
  }
  
}
