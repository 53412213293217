import React,{useState} from 'react'
import { //Librerias para la navegacion entre diferentes paginas
    NavLink
} from 'react-router-dom';
import {AiOutlineLogin} from 'react-icons/ai'
import {VscAccount} from 'react-icons/vsc';
function Navbar(){
    const [toggle,setToggle] = useState(false);
    function onMenuClick(){
        setToggle(!toggle);
    }
    return (
        <div className={toggle?"w-100 bg-white":"w-100 bg-white nave"} style={{zIndex:100,top:0, position: "sticky"}}>
            <nav className="navbar navbar-expand-lg p-3 py-1 navbar-light">
            
            <button onClick={onMenuClick} className="navbar-toggler" type="button" >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={toggle?"navbar-collapse py-2 encabezado":"navbar-collapse collapse py-2 encabezado"} >
          <ul className="navbar-nav m-auto" >
            <li className="nav-item active m-auto">
                <NavLink className="nav-link py-1" to="/login"> <AiOutlineLogin style={{marginBottom:"4px",}}/> Iniciar Sesi&oacute;n  </NavLink>
            </li>
            <li className="nav-item active m-auto">
                <NavLink className="nav-link py-1" to="/register"><VscAccount style={{marginBottom:"3px", paddingBottom:"0.7px"}}/> Registrarme </NavLink>
            </li>
          </ul>
                <ul className="navbar-nav m-auto">
                <li className="nav-item active m-auto">
                    <NavLink className="nav-link  py-1" to="/home">Inicio</NavLink>
                </li>
                <li className="nav-item m-auto ">
                    <NavLink className="nav-link py-1" to="/proyectos">Proyectos</NavLink>
                </li>
                <li className="nav-item m-auto ">
                    <NavLink className="nav-link py-1" to="/productos">Productos</NavLink>
                </li>
                <li className="nav-item m-auto d-block">
                        <NavLink className="nav-link py-1" to="/archivo">Archivo</NavLink>
                </li>
            
                
                </ul>
               
            </div>
            
            
        </nav>
            
        </div>
        
    );
}

export default Navbar;