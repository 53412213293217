import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';



function AdminNewProduct(props){
    const ref = useRef(null);
    const [items,setItems] = useState([]);
    const [otherCat,setOtherCat] = useState('');
    const [display,setDisplay] = useState('d-none');
    const [update,setUpdate] = useOutletContext();
    const [product,setProduct] = useState(
        {
            title:'',
            description:'',
            shoppingLink:''
        }
    );
    var f = new FormData();
    
    let navigate = useNavigate();
    function onChangeTitle(e) {
        setProduct((prevState)=>({
          ...prevState,
          title: e.target.value
        }));
     
    }
    
    function onChangeDescription(e){
        setProduct((prevState)=>({
          ...prevState,
          description: e.target.value
        }));
    

    }
    function onChangeShoppingLink(e){
        setProduct((prevState)=>({
          ...prevState,
          shoppingLink: e.target.value
        }));
    

    }
    function onChangeOtherCat(e){
        setOtherCat(e.target.value);
        if(e.target.value.length>0){
            setDisplay('');
        }else{
            setDisplay('d-none'); 
        }
    }
    function onOtherCatAdd(){
        setItems([...items,otherCat]);
        setOtherCat('');
        setDisplay('d-none')
    }
    function onChangeCategories(e) {
        if(e.target.checked){
            setItems([...items,e.target.value]);
        }else{
            var index = items.indexOf(e.target.value);
            if (index !== -1) {
                items.splice(index, 1);
            }
        }

    }
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
    }
    function onChangeImages(e){
        
        for(let file of e.target.files){
        if(file.type==='image/jpeg' ||  file.type==='image/png'){
            let postid = uuidv4();
            let blob = file.slice(0,file.size,"image/jpeg");
            let newFile = new File([blob],`${postid}_project.jpeg`, { type: "image/jpeg" });
            f.append("images", newFile);
        } else{
            alert("Tipo de archivo no soportado");
            ref.current.value=null;

        }}
    }
    
    async function onSend(e){
        e.preventDefault();
       
        const headers={
            "Content-type": "multipart/form-data",
            'x-access-token': props.statelog.jwt
    
        }
        
        f.append("title",product.title);
        f.append("description",product.description);
        f.append("shoppingLink",product.shoppingLink);
        for(let item of items)
            f.append("categories[]",item);
      
        axios.post('/products',  f, {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
                    setProduct({
                        title:'',
                        description:'',    
                        shoppingLink: ''
                    });
                    setItems([]);
                    ref.current.value=null;
                    setOtherCat('');

                    alert("Enhorabuena, "+respuesta.data.title+" añadido satisfactoriamente!");
                    setUpdate(!update);
                    navigate('/admin/productos/',{ replace: true });
                    
                }
                else{
                    alert("No fue posible añadir el producto");
                    setProduct({
                        title:'',
                        description:'',    
                        shoppingLink: ''
                    }); 
                    setOtherCat('');

                    ref.current.value=null;
                    setItems([]);
                    navigate('/admin/productos/',{ replace: true });
                }
            }).catch(error => {
                alert("Error, no fue posible añadir el producto err:"+error);
                setProduct({
                    title:'',
                    description:'',    
                    shoppingLink: ''
                }); 
                ref.current.value=null;
                setOtherCat('');

                setItems([]);
                navigate('/admin/productos/',{ replace: true });
            });
    }
    
    return(
        <div className='col-12'>
            <section className="d-flex  justify-content-center justify-content-center mb-0">
                    <div className="tag align-items-center">
                        <h2>AÑADIR PRODUCTO</h2>
                    </div>
                </section>
            <div className="m-auto row my-0 px-4 ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                Producto:
                            </label>
                            <input type="text" value={product.title}  onChange={onChangeTitle} className="form-control" name="title" placeholder="T&iacute;tulo del producto" required/>
            
                    </div>
                    <div className="row m-0">
                        
                            <label className="my-1">
                                Descripción:
                            </label>
                            <textarea value={product.description}  onChange={onChangeDescription} className="form-control" name="description" placeholder="Pequeña descripci&oacute;n" />
                        
                    </div>
                    <div className="row m-0">
                            
                            <label className="my-1">
                                Comprar en:   
                            </label>
                            <input type="text" value={product.shoppingLink} onChange={onChangeShoppingLink} className="form-control" name="shoppingLink" placeholder="Sitio de compra" />
                        
                    </div>
                    <div className="row m-0">
                       
                            <label className="my-1">
                                Categor&iacute;as:   
                            </label>
                            <input type="text" placeholder='Añade nuevas categor&iacute;as' value={otherCat} onChange={onChangeOtherCat} className='form-control mb-3 w-75'></input><input value="+" type="button" className={display+' p-0 mx-2 mb-3 btn btn-outline-success'} onClick={onOtherCatAdd} style={{width:"10.55%"}}/>
                            {items.map(categoria=>(
                                    <div  className="col-sm-4 d-flex form-check"  key={categoria} >
                                        <input className="form-check-input"   onChange={onChangeCategories} name="categories" type="checkbox" defaultChecked value={categoria} />
                                        <label className="form-check-label" htmlFor="categories">{categoria}</label>                        
                                    </div>)
                                )
                            }
                    </div>
                    <div className="row m-0 mb-3">
                       
                            <label className="my-1">
                                Imagenes:
                            </label>
                            <input ref={ref} type="file" multiple={true} onChange={onChangeImages} className="form-control" name="images" required/>
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-success" type="submit" value="Añadir" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminNewProduct;
