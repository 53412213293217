import React from "react";
function Card(props) {
  return (
    <div className='row text-muted'>
      <div className='col-2'></div>
      <div className="col-lg-4 col-sm-12 px-sm-3 px-1 .bg-light.bg-gradient" style={{ maxHeight: "440px", overflowY: "hidden" }}>
        <img className="backContainer w-75 m-0" src={props.img} alt={props.img} />
      </div>
      <div className="col-lg-4 col-sm-12 .bg-light.bg-gradient" style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <p className="px-5 pt-3" style={{ textAlign: "justify" }}>{props.content}</p>
        <a href={props.link} style={{ color: "#000" }}>Ver m&aacute;s</a>
      </div>
      <div className='col-2'></div>
    </div>
  );
}

export default Card;