import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';



function AdminNewBanner(props){
    const ref = useRef(null);
    const [update,setUpdate] = useOutletContext();
    const [banner,setBanner] = useState({
        code:'',
        link:'',
        media:''
    });
    var f = new FormData();
    let navigate = useNavigate();
    function onChangeCode(e) {
        setBanner((prevState)=>({
          ...prevState,
          code: e.target.value
        }));
    }
    function onChangeLink(e) {
        setBanner((prevState)=>({
          ...prevState,
          link: e.target.value
        }));
    }
   
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
    }
    function onChangeMedia(e){
        for(let file of e.target.files){
            let postid = uuidv4();
            if(file.type==='image/jpeg' ||  file.type==='image/png'){
                let blob = file.slice(0,file.size,"image/jpeg");
                let newFile = new File([blob],`${postid}_banner.jpeg`, { type: "image/jpeg" });
                f.append("media", newFile);
            } 
             else{
                alert("Tipo de archivo no soportado");
                ref.current.value = null;
            }
            
        }
    }
    
    async function onSend(e){
        e.preventDefault();
       
        const headers={
            "Content-type": "multipart/form-data",
            'x-access-token': props.statelog.jwt
    
        }
        
        f.append("code",banner.code);
        f.append("link",banner.link);

        axios.post('/banners',  f, {headers:headers})
            .then(respuesta => {
                if(respuesta.data){
                    setBanner({
                        code:'',
                    });
                    ref.current.value=null;
                    alert("Enhorabuena, "+respuesta.data.code+" añadido satisfactoriamente!");
                    setUpdate(!update);
                    navigate('/admin/banners',{ replace: true });
                    
                }
                else{
                    alert("No fue posible añadir el banner");
                    setBanner({
                        code:'',
                        link:''  
                    });
                    ref.current.value=null;
                    navigate('/admin/banners',{ replace: true });
                }
            }).catch(error => {
                alert("Error, no fue posible añadir el banner err:"+error);
                setBanner({
                    code:'',
                    link:''
                }); 
                ref.current.value=null;
                navigate('/admin/banners',{ replace: true });
            });
    }
    
    return(
        <div className='col-12'>
            <section className="d-flex  justify-content-center justify-content-center mb-0">
                    <div className="tag align-items-center">
                        <h2>AÑADIR BANNER</h2>
                    </div>
                </section>
            <div className="m-auto row my-0 px-4 ">
                <form onSubmit={onSend} >
                    <div className="row m-0 ">
                       
                            <label className="mb-1">
                                C&oacute;digo
                            </label>
                            <input type="text" value={banner.code}  onChange={onChangeCode} className="form-control" name="code" placeholder="C&oacute;digo del banner" required/>
            
                    </div>
                    <div className="row m-0 ">
                       
                       <label className="mb-1">
                           Link
                       </label>
                       <input type="text" value={banner.link}  onChange={onChangeLink} className="form-control" name="link" placeholder="Link del banner" required/>
       
                     </div>
              
                    <div className="row m-0 mb-3">
                       
                            <label className="my-1">
                                Imagen:
                            </label>
                            <input ref={ref} type="file"  onChange={onChangeMedia} className="form-control" name="media" required/>
                 
                    </div>
                    <div className="d-flex justify-content-center">
                            <input className="btn btn-outline-success" type="submit" value="Añadir" style={{margin : "10px"}}></input>
                    </div>
                </form>
            </div>
        </div>
    
    );
}

export default AdminNewBanner;
