import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function Sale() {
  const headers={
    "Content-type": "application/json"
  }
  let product;
  let params = useParams();
  axios.get('/products:'+params.productId,undefined,  {headers:headers})
  .then(respuesta => {
      if(respuesta.data){
          console.log(respuesta.data);
          product = respuesta.data;
      }
      else{
        console.log("error;nose wru");
      }
  }).catch(error => {
      console.log(error);
  });

  return (
    <main style={{ padding: '1rem' }}>
      <h2>Total Due: {product.amount}</h2>
      <p>
        {product.name}: {product.number}
      </p>
      <p>Due Date: {product.due}</p>
    </main>
  );
}