import React from 'react'
import '../estilos/header.css';

function Header(){
    return (
        <div className="position-relative w-100 bg-white" style={{zIndex:10, boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
            <div className="d-flex w-100 p-2">
                <img src={require("../imagenes/logo1.png")} alt="" style={{width:"130px", margin:"auto", zIndex:"100"}}/>    
                <div className="header w-100 h-100 position-absolute" style={{left:0,top:0}}>
                </div> 
            </div>
        </div>
    );
}

export default Header;